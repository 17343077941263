/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import WalletSelectDropdown from "src/Components/Inputs/WalletSelectDropdown";
import TransactionFeeDropdown from "src/Components/Inputs/TransactionFeeDropdown";
import { NetworkContext } from "src/Hooks/NetworkContext";
import PageWrapper from "src/Components/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Wrappers/PageWrapper2";
import ErrorModal from "src/Components/Modals/Error";
import TextArea from "src/Components/Inputs/TextArea";
import { Location, TransactionDetails } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import * as backend from "src/Backend";
import { addBalance } from "src/Hooks/util";
import FullScreenModal from "src/Components/Modals/FullScreenModal";
import ScanQR from "src/Components/Modals/Children/ScanQR";

const Send = () => {
  const navigate = useNavigate();
  const { network } = useContext(NetworkContext);
  const location = useLocation() as Location;
  const { type, asset, walletData, vault, locationHistory, lnCredentials } =
    location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState("");
  const [lnBalance, setLnBalance] = useState(0);
  const [open, setOpen] = useState(false);
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const [transaction, setTransaction] = useState<TransactionDetails>({
    sender: {
      name: walletData.name,
      address: walletData.address,
    },
    recipient: {
      name: "",
      address: "",
      conceal: "",
    },
    assetType: "",
    asset: "",
    amount: 0,
    fee: 0,
    message: "",
  });

  const items = [
    {
      name: "wallet 1",
      address: "0ehjtyje2435345234523",
      balance: "45.56",
    },
    {
      name: "wallet 2",
      address: "0ejyje3776356363",
      balance: "0.534",
    },
    {
      name: "wallet 3",
      address: "ejytej44234jye5452345",
      balance: "23.34",
    },
    {
      name: "wallet 4",
      address: "0fewf02452354353425452",
      balance: "2.34",
    },
  ];

  const items2 = [
    {
      name: "1 satoshi/vByte",
      amount: "45.56",
    },
    {
      name: "1 satoshi/vByte",
      amount: "0.534",
    },
    {
      name: "1 satoshi/vByte",
      amount: "23.34",
    },
    {
      name: "1 satoshi/vByte",
      amount: "2.34",
    },
  ];
  const items3 = [
    {
      name: "1 satoshi/vByte",
      amount: "45.56",
    },
    {
      name: "1 satoshi/vByte",
      amount: "0.534",
    },
    {
      name: "1 satoshi/vByte",
      amount: "23.34",
    },
    {
      name: "1 satoshi/vByte",
      amount: "2.34",
    },
  ];

  useEffect(() => {
    ReactGA.pageview("Send");
    if (option === "Bitcoin") {
      Mixpanel.track("Viewed Send Bitcoin");
    } else if (option === "Asset") {
      Mixpanel.track("Viewed Send Asset Transaction", {
        Asset: asset,
        Transaction: transaction,
      });
    }
  }, []);

  useEffect(() => {
    if (option === "Lightning") {
      backend.ln_get_balance(lnCredentials.accessToken).then((res) => {
        console.log(res[0].balance);
        setLnBalance(Number(res[0].balance));
      });
    }
  }, [option]);

  const handleSatSubmit = () => {
    const transactionData = {
      ...transaction,
      assetType: "sats",
    };
    if (transaction.amount >= 546) {
      if (Number(transaction.amount) % 1 !== 0) {
        setError({
          title: `Error creating transaction`,
          message:
            "You cannot send fractions of a Sat. Please remove decimal places. If you are trying to introduce amount in bitcoins, convert to Sats",
        });
        setOpen(true);
      } else if (
        Number(addBalance(walletData?.balance)) >= transaction.amount
      ) {
        transaction.fee = 1000;
        ReactGA.event({
          category: "Send",
          action: "confirm",
        });
        navigate("/confirm", {
          state: {
            ...location.state,
            transaction: transactionData,
            locationHistory: [...locationHistory, window.location.hash],
            type: option,
          },
        });
      } else {
        setError({
          title: `Error creating transaction`,
          message: "Insufficient funds",
        });
        setOpen(true);
      }
    } else {
      setError({
        title: `Error creating transaction`,
        message:
          "You must send a minimum of 546 sats. Look into the dust limit and uneconomical outputs.",
      });
      setOpen(true);
    }
  };

  const handleTokenSubmit = () => {
    if (asset.balance && asset.balance >= BigInt(transaction.amount)) {
      const transactionData = {
        ...transaction,
        asset: asset.id,
        assetType: option === "UDA" ? "UDA" : "token",
      };
      ReactGA.event({
        category: "Send",
        action: "confirm asset",
      });
      navigate("/confirm", {
        state: {
          ...location.state,
          transaction: transactionData,
          locationHistory: [...locationHistory, window.location.hash],
        },
      });
    } else {
      setError({
        title: `Error creating transaction`,
        message: "Insufficient funds",
      });
      setOpen(true);
    }
  };

  return (
    <>
      {option === "" && (
        <PageWrapper2
          className=""
          title="Send"
          handlePageBack={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials },
            })
          }
          button={
            <RoundedButton
              className="w-full text-lg text-black border-2 border-black dark:text-yellow-500 dark:border-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault, lnCredentials },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Bitcoin")}
            >
              Send Bitcoin
            </RoundedButton>
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Lightning")}
            >
              Send Bitcoin (Lightning network)
            </RoundedButton>
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Asset")}
            >
              Send Asset
            </RoundedButton>
            <RoundedButton
              className="hidden text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Invoice")}
            >
              Pay Invoice
            </RoundedButton>
          </div>
        </PageWrapper2>
      )}
      {option === "Bitcoin" && (
        <PageWrapper
          title="Send Sats"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", { state: { wallet: walletData.name, vault } })
          }
          handleSubmit={() => handleSatSubmit()}
        >
          <div className="w-full">
            {network !== "Bitcoin Testnet" && (
              <WalletSelectDropdown
                label="From"
                items={items}
                transaction={transaction}
                setTransaction={setTransaction}
              />
            )}
            <div className="flex flex-row w-full m-auto">
              <div className="w-full">
                <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-500">
                  To
                </h3>
                <form className="flex flex-row w-full">
                  <div className="flex flex-col justify-center mx-auto font-sans flex-grow-default">
                    <input
                      className="w-full p-3 text-sm xs:text-base bg-gray-300 focus:ring-1 focus:ring-gray-700 placeholder-gray-600 focus:border-gray-700 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-600 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-500"
                      onChange={(e) =>
                        setTransaction({
                          ...transaction,
                          recipient: {
                            ...transaction.recipient,
                            address: e.target.value,
                            name: "recipient",
                          },
                        })
                      }
                      value={transaction.recipient.address}
                      placeholder="Enter recipient's address"
                    />
                  </div>
                  <RoundedButton
                    className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                    onClick={() => {
                      navigator.getUserMedia =
                        navigator.getUserMedia ||
                        navigator.webkitGetUserMedia ||
                        navigator.mozGetUserMedia;

                      if (navigator.getUserMedia) {
                        navigator.getUserMedia(
                          { audio: false, video: { width: 1280, height: 720 } },
                          (stream) => true,
                          (err) => {
                            if (err.message) {
                              setError({
                                title: "Error Scanning QR",
                                message: `${err.name}: ${err.message}`,
                              });
                              setOpen(true);
                            }
                          }
                        );
                      } else {
                        setError({
                          title: "Error Opening Camera",
                          message:
                            "getUserMedia is not supported on this device",
                        });
                        setOpen(true);
                      }
                      setOpenQrScanner(true);
                    }}
                  >
                    Scan QR
                  </RoundedButton>
                </form>
              </div>
            </div>
            <TextField
              label="Amount in SATS"
              type="number"
              onChange={(e) =>
                setTransaction({ ...transaction, amount: e.target.value })
              }
              placeholder="Enter amount"
              className="w-full p-3"
            />
            {network !== "Bitcoin Testnet" && (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <TransactionFeeDropdown
                    label="Gas Fee"
                    type="fee"
                    items={items2}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                  <TransactionFeeDropdown
                    label="Gas Limit"
                    type="limit"
                    items={items3}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                </div>
                <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                  Transaction Fees
                </h3>
              </>
            )}
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
              onKeyDown={(e) =>
                e.key === "Enter" ? () => handleSatSubmit() : null
              }
            />
          </div>
        </PageWrapper>
      )}
      {option === "Lightning" && (
        <PageWrapper
          title="Send With Lightning Network"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials },
            })
          }
          handleSubmit={() => {
            try {
              const amount =
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("light-bolt11-decoder").decode(lnInvoice).sections[2]
                  .value * 0.001;
              if (lnBalance >= amount / 100_000_000) {
                navigate("/lightningconfirm", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    lnInvoice,
                  },
                });
              } else {
                setError({
                  title: "Error Paying Invoice",
                  message: "LN wallet balance too low",
                });
                setOpen(true);
              }
            } catch (err) {
              if (err instanceof Error) {
                setError({
                  title: "Error Paying Invoice",
                  message: `${err.name}: ${err.message}`,
                });
                setOpen(true);
              }
            }
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-500">
                LN Invoice
              </h3>
              <form className="flex flex-row w-full">
                <div className="flex flex-col justify-center mx-auto font-sans flex-grow-default">
                  <input
                    type="string"
                    className="w-full p-3 text-base placeholder-gray-600 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-600 bg-gray-300 focus:bg-gray-350 rounded-md text-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                    placeholder="Enter recipient's invoice"
                    onChange={(e) => setLnInvoice(e.target.value)}
                    value={lnInvoice}
                  />
                </div>
                <RoundedButton
                  className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                  onClick={() => {
                    navigator.getUserMedia =
                      navigator.getUserMedia ||
                      navigator.webkitGetUserMedia ||
                      navigator.mozGetUserMedia;

                    if (navigator.getUserMedia) {
                      navigator.getUserMedia(
                        { audio: false, video: { width: 1280, height: 720 } },
                        (stream) => true,
                        (err) => {
                          if (err.message) {
                            setError({
                              title: "Error Scanning QR",
                              message: `${err.name}: ${err.message}`,
                            });
                            setOpen(true);
                          }
                        }
                      );
                    } else {
                      setError({
                        title: "Error Opening Camera",
                        message: "getUserMedia is not supported on this device",
                      });
                      setOpen(true);
                    }
                    setOpenQrScanner(true);
                  }}
                >
                  Scan QR
                </RoundedButton>
              </form>
            </div>
            <p className="w-full mt-6 text-base font-medium text-center text-gray-900 truncate dark:text-gray-500">
              <span className="font-light">LN wallet balance:</span>{" "}
              {lnBalance
                ? lnBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 8,
                  })
                : 0}{" "}
              BTC
            </p>
          </div>
        </PageWrapper>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", { state: { wallet: walletData.name, vault } })
          }
          handleSubmit={() => handleTokenSubmit()}
        >
          <div className="w-full">
            {network !== "Bitcoin Testnet" && (
              <WalletSelectDropdown
                label="From"
                items={items}
                transaction={transaction}
                setTransaction={setTransaction}
              />
            )}
            <TextField
              label="To"
              onChange={(e) =>
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    conceal: e.target.value,
                    name: "recipient",
                  },
                })
              }
              placeholder="Enter recipient's conceal"
              className="w-full p-3"
            />
            {option === "Asset" && (
              <TextField
                label="Amount"
                type="number"
                onChange={(e) =>
                  setTransaction({ ...transaction, amount: e.target.value })
                }
                placeholder="Enter amount"
                className="w-full p-3"
              />
            )}
            {network !== "Bitcoin Testnet" && (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <TransactionFeeDropdown
                    label="Gas Fee"
                    type="fee"
                    items={items2}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                  <TransactionFeeDropdown
                    label="Gas Limit"
                    type="limit"
                    items={items3}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                </div>
                <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                  Transaction Fees
                </h3>
              </>
            )}
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
          </div>
        </PageWrapper>
      )}
      {option === "Invoice" && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", { state: { wallet: walletData.name, vault } })
          }
          handleSubmit={() =>
            navigate("/confirm", { state: { walletData, vault, transaction } })
          }
        >
          <div className="w-full">
            {network !== "Bitcoin Testnet" && (
              <WalletSelectDropdown
                label="From"
                items={items}
                transaction={transaction}
                setTransaction={setTransaction}
              />
            )}
            <TextField
              label="To"
              onChange={(e) =>
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    address: e.target.value,
                    name: "recipient",
                  },
                })
              }
              placeholder="Enter recipient's address"
              className="w-full p-3"
            />
            <TextField
              label="Amount"
              type="number"
              onChange={(e) =>
                setTransaction({ ...transaction, amount: e.target.value })
              }
              placeholder="Enter amount"
              className="w-full p-3"
            />
            {network !== "Bitcoin Testnet" && (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <TransactionFeeDropdown
                    label="Gas Fee"
                    type="fee"
                    items={items2}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                  <TransactionFeeDropdown
                    label="Gas Limit"
                    type="limit"
                    items={items3}
                    transaction={transaction}
                    setTransaction={setTransaction}
                  />
                </div>
                <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                  Transaction Fees
                </h3>
              </>
            )}
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
          </div>
        </PageWrapper>
      )}
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
      <FullScreenModal open={openQrScanner} setOpen={setOpenQrScanner}>
        <ScanQR
          setQr={
            option === "Bitcoin"
              ? (res) => {
                  setTransaction({
                    ...transaction,
                    recipient: {
                      ...transaction.recipient,
                      address: res,
                      name: "recipient",
                    },
                  });
                }
              : (res) => {
                  try {
                    const amount =
                      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                      require("light-bolt11-decoder").decode(res).sections[2]
                        .value * 0.001;
                    if (lnBalance > amount / 100_000_000) {
                      navigate("/lightningconfirm", {
                        state: {
                          wallet: walletData.name,
                          vault,
                          lnCredentials,
                          lnInvoice: res,
                        },
                      });
                    } else {
                      setError({
                        title: "Error Paying Invoice",
                        message: "LN wallet balance too low",
                      });
                      setOpen(true);
                    }
                  } catch (err) {
                    if (err instanceof Error) {
                      setError({
                        title: "Error Paying Invoice",
                        message: `${err.name}: ${err.message}`,
                      });
                      setOpen(true);
                    }
                  }
                }
          }
          open={openQrScanner}
          setOpen={setOpenQrScanner}
        />
      </FullScreenModal>
    </>
  );
};

export default Send;
