/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

const ReleaseNotes = ({ ...props }) => (
  <div className="p-6 font-jetbrains xs:p-9 dark:bg-transparent md:p-12">
    <h1 className="pb-3 text-xl border-b-2 border-gray-700 xs:text-3xl dark:border-darkmode-400 dark:text-white">
      BitMask v{props.version} release notes
    </h1>
    <ul className="flex flex-col pt-6 space-y-3">
      {props.releaseNotes.map((note) => (
        <li
          className="text-base text-left list-disc dark:text-gray-400 sm:text-lg lg:text-xl"
          key={JSON.stringify(note)}
        >
          {note}
        </li>
      ))}
    </ul>
  </div>
);

export default ReleaseNotes;
