/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* global chrome */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReactGA from "react-ga";
import { TEST_IDS } from "tests/ids";

import Loader from "src/Components/Loaders/Loader";
import { MainNavContext } from "src/Hooks/MainNavContext";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import Footer from "src/Components/Footer";
import lib, { isExtension } from "src/lib";
import useWindowSize from "src/Hooks/useWindowSize";
import { Vault, WalletData, LnCredentials } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { classNames } from "src/Hooks/util";
// import Image from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { v4 as uuidv4 } from "uuid";
import CustomSwiper from "src/Components/Swiper/CustomSwiper";
import {
  rewriteAssetImageUrl,
  rewriteImageUrl,
} from "../utils/rewriteImageUrl";

const Welcome = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const { setMainNav } = useContext(MainNavContext);
  const navigate = useNavigate();
  const [view, setView] = useState(true);
  const [isVaultLoading, setIsVaultLoading] = useState(true);
  const featuresRef = useRef(null);
  const web3Ref = useRef(null);
  const [, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const postEmail = async () => {
    await fetch("/api/emailsignup", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  };

  const onSubmit = () => {
    if (email !== "") {
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        setError(false);
        postEmail().then(() => {
          setEmail("");
          setSuccess(true);
        });
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };
  const CARD_OFFSET = 40;
  const SCALE_FACTOR = 0.06;

  const blogs = [
    {
      title: "Who is building Diba?",
      description: "Learn about the people buildng the DIBA products.",
      img: "images/whoisbuildingdiba.png",
      link: "/blog/WhoIsBuildingDiba",
      id: uuidv4(),
    },
    {
      title: "Why This Design? The Story behind DIBA’s Merch.",
      description:
        "Merch without concept is just merch. It’s a sticker or shirt in the company’s color palette with elements like a logo, website or a bit of text.",
      img: "images/Rectangle622.png",
      link: "/blog/WhyThisDesign",
      id: uuidv4(),
    },
    {
      title: "NFT NYC 2022 showcases Bitcoin Art with DIBA",
      description:
        "Art and Assets built on Bitcoin make their existence known into the mainstream NFT scene. DIBA presents on the NFT NYC Conference panel and attends 50+ surrounding events.",
      img: "images/NFTNYC2022.jpeg",
      link: "/blog/NFTNYC2022",
      id: uuidv4(),
    },
    {
      title: "DIBA Presents at 2022 Bitcoin Conference",
      description:
        "This year, DIBA had a presence at the largest, most reputable Bitcoin event in the world through TALKS, DIGITAL ART, and DIBA’s first ever official AFTERPARTY.",
      img: "images/DibaPresents2022BTCCon.webp",
      link: "/blog/2022BTCCon",
      id: uuidv4(),
    },
    {
      title: "BitMask on Chrome Store",
      description:
        "DIBA Global is proud to share the latest BitMask, the wallet for utility directly on bitcoin. Version 0.1.1.0 is available to download as a Google Chrome extension.",
      img: "images/BitmaskonChromeStore.webp",
      link: "/blog/BitmaskonChrome",
      id: uuidv4(),
    },
    {
      title:
        "Diba is a Top 10 Finalist at Bitcoin Conference 2021 Pitch Competition",
      description:
        "Out of hundreds of submissions, Diba was selected to present their project as a Top 10 Finalist at the 2021 Bitcoin Conference in Miami on Whale Day.",
      img: "images/DibaIsTopTen.webp",
      link: "/blog/DibaIsTop10",
      id: uuidv4(),
    },
  ];

  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 80,
  };

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(`${window.location.href.split("?")[0]}`, "_blank");
    win?.focus();
  };

  const getWalletMode = () =>
    window.location.href.includes("chrome-extension://")
      ? "Extension"
      : "Web Wallet";

  function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (savedCallback.current) savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    setView(!view);
  }, 5000);

  useEffect(() => {
    setHeight(size.height - 80);
  }, [size]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
      debug: false,
      gaAddress: "analytics.js",
      gaOptions: {
        cookieDomain: "none",
      },
    });
    ReactGA.ga("set", "checkProtocolTask");
    ReactGA.pageview("Welcome");
    (async () => {
      const isPopup = window.location.href.split("?").pop() === "popup";
      Mixpanel.register_once({
        "Wallet Mode": getWalletMode(),
      });
      if (localStorage.getItem("descriptor")) {
        const result = await lib.storageGet<WalletData>(["wallet"]);

        if (result.wallet) {
          setIsVaultLoading(true);

          if (setMainNav) setMainNav(true);

          const { vault } = await lib.storageGet<Vault>(["vault"]);
          const { lnCredentials } = await lib.storageGet<LnCredentials>([
            "lnCredentials",
          ]);

          if (vault) {
            ReactGA.event({
              category: "Welcome",
              action: "wallet",
            });
            navigate("/wallet", {
              replace: true,
              state: { wallet: result.wallet.name, vault, lnCredentials },
            });
          } else {
            ReactGA.event({
              category: "Welcome",
              action: "sign in",
            });
            navigate("/signin", { replace: true });
          }
        } else if (
          isPopup &&
          window.location.href.split("/").pop()?.startsWith("index.html")
        ) {
          setIsVaultLoading(false);
          handleRedirectToFullscreenTab();
        } else {
          setIsVaultLoading(false);
        }
      } else if (
        isPopup &&
        window.location.href.split("/").pop()?.startsWith("index.html")
      ) {
        setIsVaultLoading(false);
        handleRedirectToFullscreenTab();
      } else {
        setIsVaultLoading(false);
        Mixpanel.track("Viewed Landing Page");
      }
    })();
  }, []);

  if (isVaultLoading) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-screen m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradient dark:bg-newdarkmode-700"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-x-hidden overflow-y-auto font-jetbrains">
      <SecondaryNav
        menuItems={[
          { name: "Deep Web3", ref: web3Ref },
          { name: "Features", ref: featuresRef },
          { name: "FAQ", route: "faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="sticky w-full my-auto overflow-y-auto dark:darkscrollbar"
      >
        <div className="flex flex-col justify-center h-auto overflow-y-auto">
          <div className="h-full min-h-full">
            <div className="flex flex-col justify-center h-auto my-auto">
              <section className="relative grid h-auto min-w-full min-h-screen grid-cols-1 my-auto overflow-hidden lg:grid-cols-12 bggradient sm:pt-8 lg:pt-0">
                <div className="order-1 w-10/12 h-auto col-span-7 lg:pt-12 m-auto text-left text-black dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-6 lg:pl-6">
                  <div className="relative z-20 w-full m-auto text-left text-black dark:text-white">
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl">
                      BitMask Wallet - The Gateway for DeepWeb3 Utility on
                      Bitcoin{" "}
                    </h1>
                  </div>
                  <div className="relative z-20 w-full text-left lg:pt-0">
                    <p className="my-6 font-light leading-9 tracking-normal text-lg sm:text-xl lg:p-0 md:tracking-wide">
                      BitMask is a browser extension for accessing decentralized
                      applications on the Bitcoin blockchain using RGB protocol
                      among others.{" "}
                    </p>
                  </div>
                  <div className="relative z-20 w-full text-left lg:pt-0">
                    <p className="my-3 font-light leading-9 tracking-normal text-lg sm:text-xl lg:p-0 md:tracking-wide">
                      Discover Bitcoin (NFT) UDAs, Bitcoin Finance, and other
                      assets.
                    </p>{" "}
                  </div>
                  <div className="relative z-20 hidden w-full text-left md:flex lg:pt-0">
                    <p className="font-light leading-9 tracking-normal text-lg sm:text-xl lg:p-0 md:tracking-wide">
                      Experience true financial sovereignty with BitMask, the
                      taproot-enabled Bitcoin and Lightning Network Wallet.{" "}
                    </p>
                  </div>
                  <div className="flex lg:pb-30">
                    <div className="flex flex-col mx-auto lg:mr-auto md:flex-row w-[20rem] sm:w-[20rem] md:w-[40rem] md:mt-6 md:gap-10 pt-3">
                      {isExtension ? (
                        <>
                          <RoundedButton
                            style={{ background: "#272F3F" }}
                            className="z-20 w-auto h-16 px-3 mt-6 text-base bg-gray-100 text-darkmode-700 md:max-w-120 xl:max-w-full rounded-4xl"
                            onClick={() => navigate("/newuser")}
                          >
                            <div className="flex flex-row mx-auto my-auto">
                              <p className="mx-auto my-auto text-lg lg:text-xl">
                                Continue with Extension
                              </p>
                            </div>
                          </RoundedButton>
                          <RoundedButton
                            className="z-20 w-auto h-16 px-3 mt-6 text-base bg-gray-100 text-darkmode-700 md:max-w-120 xl:max-w-full rounded-4xl"
                            onClick={() =>
                              window.location.assign("https://bitmask.app")
                            }
                          >
                            <p className="mx-auto my-auto text-lg lg:text-xl">
                              Go to Web Wallet
                            </p>
                          </RoundedButton>
                        </>
                      ) : (
                        <>
                          <RoundedButton
                            style={{ background: "#272F3F" }}
                            className="z-20 w-auto h-16 mt-6 text-base bg-gray-100 text-lightmode-900 md:max-w-120 xl:max-w-full rounded-4xl"
                            onClick={() =>
                              window.location.assign(
                                "https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp"
                              )
                            }
                          >
                            {/* <div className="flex flex-row w-full mx-auto my-auto"> */}
                            <img
                              className="w-auto h-auto mx-auto my-auto max-h-12"
                              src="images/Googlechromedownload2.png"
                              alt="Google chrome extension download"
                            />
                            {/* </div> */}
                          </RoundedButton>
                          <RoundedButton
                            className="z-20 w-auto h-16 mt-6 text-base bg-gray-100 text-darkmode-700 md:max-w-120 xl:max-w-full rounded-4xl"
                            onClick={() => navigate("/newuser")}
                          >
                            <p className="mx-auto my-auto text-lg sm:text-[1.05rem] px-3">
                              Continue with Web Wallet
                            </p>
                          </RoundedButton>
                        </>
                      )}
                    </div>

                    {/* <RoundedButton
                      className="z-20 flex mx-auto text-lg bg-yellow-500 xs:hidden text-darkmode-900 w-80"
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}}
                    >
                      Get Started
                    </RoundedButton> */}
                  </div>
                </div>
                <div className="absolute top-0 left-0 bottom-0 right-0 m-auto">
                  <img
                    className="hidden xs:flex md:hidden xs:w-auto xs:h-full"
                    src="images/LineWaveMedium.png"
                    alt="Google chrome extension download"
                  />
                </div>
                <div className="absolute top-0 left-0 bottom-0 right-0 m-auto">
                  <img
                    className="hidden md:flex xs:w-auto xs:h-full"
                    src="images/LineWave.png"
                    alt="Google chrome extension download"
                  />
                </div>
                <div className="absolute xs:hidden order-2 h-full">
                  <img
                    className="w-auto h-full"
                    src="images/LineWaveMobile.png"
                    alt="Google chrome extension download"
                  />
                </div>
                <div className="hidden lg:flex absolute order-2 bottom-0 right-0">
                  <img
                    className="w-auto h-auto"
                    src="images/Layer31.png"
                    alt="Google chrome extension download"
                  />
                </div>
                {/* <div className="absolute right-0 bottom-0 order-2 z-0 ml-auto mr-0">
                  <img
                    className="my-0 hidden xs:flex lg:hidden"
                    src="images/BitmaskwithMultipleWalletsnofooter1.png"
                    width={260}
                    height={200}
                    alt="macbook with bitmask running"
                  />
                </div> */}
                <div className="hidden lg:flex lg:order-2 absolute top-1/2 transform -translate-y-1/2 -right-12 max-w-[30rem] xl:max-w-[40rem]">
                  <img
                    className=""
                    src="images/BitmaskwithMultipleWalletsnofooter1.png"
                    width={596}
                    height={924}
                    alt="macbook with bitmask running"
                  />
                </div>
                {/* <div className="hidden lg:flex lg:order-2 absolute top-[30rem] transform -translate-y-30 -right-12 max-w-[30rem] xl:max-w-[40rem]">
                  <img
                    className=""
                    src="images/demo1.gif"
                    width={493}
                    height={924}
                    alt="macbook with bitmask running"
                  />
                </div> */}
              </section>

              <section
                style={{ background: "#101C25" }}
                className="relative hidden h-auto my-auto overflow-hidden md:flex lg:pt-0"
              >
                <div className="flex flex-row order-1 w-10/12 h-auto col-span-6 m-auto text-black dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-5 py-9">
                  <div className="relative z-20 flex flex-col justify-center w-full m-auto text-black dark:text-white">
                    <h1 className="mr-6 text-xs text-left md:text-sm lg:text-base md:text-left">
                      Unlock product potential and insider tips. Sign up for our
                      exclusive newsletter.
                    </h1>
                  </div>
                  <div className="flex flex-row w-full xs:mx-auto 2xl:flex-row">
                    <input
                      id="link"
                      name="link"
                      className="flex-auto min-w-52 xs:max-w-full w-auto h-12 py-3 pl-2 pr-3 my-4 mr-auto text-base font-light placeholder-gray-500 bg-gray-100 rounded-lg border-darkmode-100 sm:mr-4 xs:min-w-64 2xl:w-auto lg:max-w-96 dark:placeholder-darkmode-100 dark:bg-newdarkmode-600 dark:text-gray-300 dark:border-darkmode-100 border-1/2 focus:outline-none focus:text-gray-900 dark:focus:text-gray-300 focus:placeholder-gray-500 focus:ring-1 focus:ring-green-400 focus:border-green-400 sm:text-sm"
                      placeholder="Enter Email"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setSuccess(false);
                      }}
                      onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : null)}
                    />
                    {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                      <p className="mb-4 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:hidden">
                        Please enter a valid email address.
                      </p>
                    )}
                    <RoundedButton
                      onClick={() => onSubmit()}
                      className="flex w-auto h-12 mx-auto my-auto ml-3 font-bold text-black bg-yellow-500 rounded-lg md:px-14"
                    >
                      Subscribe
                    </RoundedButton>
                  </div>
                  {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                    <p className="hidden my-auto text-sm font-thin tracking-wide text-gray-500 md:text-base xl:block ml-8">
                      Please enter a valid email address
                    </p>
                  )}
                  {success && (
                    <p className="my-auto text-sm font-thin tracking-wide text-gray-500 md:text-base ml-8">
                      Thank you! We will keep you in the loop.
                    </p>
                  )}

                  {/* <input
                      id="link"
                      name="link"
                      className="flex-auto w-auto h-12 py-3 pl-2 pr-3 mx-auto my-auto mr-auto text-base font-light placeholder-gray-500 bg-gray-100 rounded-lg border-darkmode-100 sm:mr-4 min-w-64 2xl:w-auto lg:max-w-96 dark:placeholder-darkmode-100 dark:bg-newdarkmode-600 dark:text-gray-300 dark:border-darkmode-100 border-1/2 focus:outline-none focus:text-gray-900 dark:focus:text-gray-300 focus:placeholder-gray-500 focus:ring-1 focus:ring-green-400 focus:border-green-400 sm:text-sm"
                      placeholder="Enter Email"
                      type="text"
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                    />
                    <RoundedButton
                      className="z-20 w-24 mx-auto my-auto ml-2 text-base bg-yellow-500 text-darkmode-900"
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}}
                    >
                      Subscribe
                      
                    </RoundedButton> */}
                </div>
              </section>
              <section
                className="relative h-auto min-h-screen pt-6 my-auto overflow-hidden xs:hidden sm:pt-8 lg:pt-0"
                style={{
                  backgroundImage: "url(images/PerspectiveMockupBitmask12.png)",
                  backgroundSize: "cover",
                }}
              >
                <div className="absolute bottom-0 left-0 w-auto h-auto col-span-5 bg-opacity-75 bg-gray-700 mx-6 mb-48 max-w-80 xs:max-w-120 lg:max-w-xl md:mx-40 rounded-xl">
                  <p className="mx-6 my-3 text-lg font-light text-white md:text-xl xl:text-2xl">
                    {" "}
                    Open source wallet for developers - Build secure and
                    censorship-resistant products.{" "}
                  </p>
                </div>
              </section>

              <section
                className="relative hidden h-auto min-h-screen grid-cols-1 pt-6 my-auto overflow-hidden xs:grid lg:grid-cols-12 sm:pt-8 lg:pt-0"
                style={{
                  backgroundImage: "url(images/PerspectiveMockupBitmask1.png)",
                  backgroundSize: "cover",
                }}
              >
                <div className="absolute bottom-0 left-0 w-auto h-auto col-span-5 mx-20 my-40 bg-gray-700 bg-opacity-75 max-w-80 xs:max-w-120 lg:max-w-xl md:mx-40 rounded-xl">
                  <p className="mx-8 my-8 text-base font-light text-white md:text-xl xl:text-2xl">
                    {" "}
                    Open source wallet for developers - Build secure and
                    censorship-resistant products.{" "}
                  </p>
                  {/* </div>
                <div className="relative z-10 order-2 hidden w-10/12 h-auto col-span-6 m-auto text-center text-black dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-5">
                  <div className="relative z-20 w-full m-auto text-black dark:text-white">
                    <img
                      className="z-0 flex m-6 ml-auto sm:hidden y-6"
                      src="images/bitmask.webp"
                      alt="macbook with bitmask running"
                    />
                    <h1 className="my-6 text-4xl text-left">
                      BitMask is The Gateway Wallet for Web 3 Utility on Bitcoin
                    </h1>
                    <div className="flex flex-col my-6 space-y-6 text-left ">
                      <p>
                        {" "}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        {" "}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </div>
                  </div> */}
                </div>
                {/* 
                <div className="items-center hidden col-span-6 mx-auto my-auto text-black sm:flex 4xl:col-span-7 lg:order-2 dark:text-white">
                  <div
                    className="items-center hidden h-auto md:flex w-60 rounded-2xl my-30"
                    style={{
                      background:
                        "linear-gradient(144.22deg, #A93B80 0%, #7E276C 9.24%, #4A1B5A 23.22%, #291F53 39.61%, #112A50 56.71%, #12223B 96.64%)",
                    }}
                  >
                    <img
                      className="hidden mt-8 mb-8 sm:flex h-80 w-60 ml-30 mr-30"
                      src="images/bitmask.webp"
                      alt="macbook with bitmask running"
                    />
                  </div>
                </div> */}
              </section>

              <section
                style={{
                  background:
                    "linear-gradient(131.07deg, #A93B80 5.22%, #7E276C 13.29%, #4A1B5A 25.5%, #291F53 35.61%, #112A50 46.32%, #12223B 59.28%, #291F53 72.01%, #4A1B5A 77.44%, #7E276C 84.26%, #A93B80 89.6%)",
                }}
                className="relative justify-center hidden min-h-[70vh] h-auto pt-6 my-auto overflow-hidden sm:pt-8 lg:pt-0 sm:flex"
              >
                <div className="m-auto">
                  <img
                    className="relative z-20"
                    src="images/Bitmaskmockupfloating1.png"
                    alt="array of bitmask screens"
                  />
                </div>
              </section>
              <section
                ref={web3Ref}
                id="web3"
                className="relative h-auto pt-6 my-auto overflow-hidden sm:pt-8 lg:pt-0 bggradient"
              >
                <div className="mx-6 my-12 sm:my-20 sm:mx-12">
                  <div className="flex flex-col order-2 w-full h-auto col-span-6 m-auto text-center text-black md:w-10/12 dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-5">
                    <div className="relative z-20 flex flex-col justify-center w-full m-auto text-black dark:text-white">
                      <h1 className="text-3xl sm:text-4xl ">
                        Welcome to the Deep Web3
                      </h1>
                      <div className="mt-6 text-lg mb-9">
                        <p> One that's controlled by you.</p>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center mx-auto z-20 gap-x-12 md:gap-x-20 md:gap-y-12 gap-y-6">
                      <a href="https://bitcoin.org/en/">
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] hover:bg-newdarkmode-700 duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Bitcoin Network{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            A trustless and decentralized settlement layer,
                            public ledger and timechain.
                          </p>
                        </div>
                      </a>
                      <a href="https://lightning.network/">
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Lightning Network{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            The payment layer with very low fees and 40 million
                            transactions per second.
                          </p>
                        </div>
                      </a>
                      <a href="https://rgb.info/">
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            RGB Smart-Contract{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            A smart contract system that is private, scalable,
                            and client-side validated.
                          </p>
                        </div>
                      </a>
                      <a href="https://github.com/diba-io/carbonado">
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Carbonado{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            An apocalypse-resistant data storage format for the
                            truly paranoid.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="relative h-full w-full bottom-0 left-0 order-2 z-0">
                  <img
                    className="absolute bottom-0 left-0 z-0"
                    src="images/large3Dcircle.png"
                    alt="Google chrome extension download"
                  />
                </div>
                <div className="absolute h-full w-full top-4 right-4 xs:top-20 xs:right-20 z-0">
                  <img
                    className="absolute z-0 top-0 right-0"
                    src="images/small3Dcircle.png"
                    alt="Google chrome extension download"
                  />
                </div>
              </section>

              <section
                ref={featuresRef}
                style={{ background: "#101C25" }}
                className="relative flex flex-col justify-center h-auto overflow-hidden xl:flex-row sm:pt-8 lg:pt-0"
              >
                <div className="flex flex-wrap order-2 object-cover w-full h-full mt-auto">
                  <img
                    className="z-0 object-cover w-full h-full max-h-[35rem]"
                    src="images/dibamultipledevicesmockup.png"
                    alt="macbook with bitmask running"
                  />
                </div>
                <div className="flex justify-center order-1 h-full col-span-6 my-auto text-right text-black dark:text-white 4xl:col-span-7">
                  <div className="relative z-20 flex flex-col flex-wrap justify-center w-full p-12 m-auto text-black dark:text-white">
                    <h1 className="text-4xl text-left">
                      DIBA powered by BitMask{" "}
                    </h1>
                    <div className="flex flex-row pt-6 mx-auto my-auto ml-0 text-left">
                      <img
                        className="z-0 mx-auto my-auto mr-6"
                        src="images/Rectangle620.png"
                        alt="yellow diamond"
                      />
                      <p className="text-lg md:text-lg">
                        {" "}
                        Create UDAs (Unique Digital Assets) for free, instantly.
                        No cost or delay.
                      </p>
                    </div>
                    <div className="flex flex-row pt-6 mx-auto my-auto ml-0 text-left">
                      <img
                        className="z-0 mx-auto my-auto mr-6"
                        src="images/Rectangle620.png"
                        alt="yellow diamond"
                      />
                      <p className="text-lg md:text-lg">
                        {" "}
                        Collect and trade your UDAs (Bitcoin NFTs) that are
                        bound to Bitcoin’s UTXO.
                      </p>
                    </div>
                    <div className="flex flex-row pt-6 mx-auto my-auto ml-0 text-left">
                      <img
                        className="z-0 mx-auto my-auto mr-6"
                        src="images/Rectangle620.png"
                        alt="yellow diamond"
                      />
                      <p className="text-lg md:text-lg">
                        {" "}
                        Sleep soundly knowing that BitMask's multiple encryption
                        layers guarantee the safety of your assets and eradicate
                        the risk of rug pulls.{" "}
                      </p>
                    </div>
                    <div className="flex flex-row pt-12 mx-auto my-auto text-left">
                      <a href="https://diba.io/">
                        <RoundedButton
                          className="z-20 w-auto mx-auto my-auto px-6 text-xl bg-yellow-500 text-darkmode-900"
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onClick={() => {}}
                        >
                          Check it out
                        </RoundedButton>
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="mx-6 my-20" id="blog">
                <CustomSwiper>
                  <div className="p-12 mx-6">
                    {blogs.map((blog) => (
                      <SwiperSlide className="swiper-slide" key={blog.id}>
                        <div className="w-auto py-6 my-auto md:mx-3 h-128 md:h-136 rounded-xl">
                          <div className="w-auto overflow-hidden cursor-pointer bg-newdarkmode-800 mb-18 h-102 md:h-112 rounded-xl">
                            <Link to={blog.link} key={blog.id}>
                              <div className="relative z-0 select-none h-72">
                                <img
                                  className="pointer-events-none select-none w-full h-full object-cover rounded-t-xl"
                                  src={rewriteImageUrl(blog.img)}
                                  crossOrigin="anonymous"
                                  alt="UDA media"
                                />
                              </div>
                              <div className="z-10 h-auto px-4 pt-3 pb-2 text-gray-200 select-none">
                                <h3 className="pb-2 text-base font-thin text-center text-white truncate md:text-lg font-integral">
                                  {blog.title}
                                </h3>
                                <p className="mx-3 my-auto overflow-hidden font-sans text-sm text-center md:text-base overflow-ellipsis line-clamp-3 leading-7">
                                  {blog.description}
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </CustomSwiper>
              </section>
              {/* <section
                className="relative h-auto pt-6 my-auto overflow-hidden sm:pt-8 lg:pt-0"
                style={{
                  backgroundImage: "url(images/Untitled-4new3.png)",
                  backgroundSize: "cover",
                }}
              >
                <div className="mx-12 my-20">\ </div>
              </section> */}
            </div>
          </div>
          <div className="sm:pt-0">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
