/* eslint-disable no-unused-vars */
import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import LogoDark from "src/Components/Icons/LogoDark";
import HamburgerMenu from "src/Components/Icons/HamburgerMenu";
import XMarkIcon from "src/Components/Icons/XMarkIcon";
import Telegram from "src/Components/Icons/Telegram";
import Twitter from "src/Components/Icons/Twitter";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";

const SecondaryNav = ({ ...props }) => (
  <Disclosure as="header" className="relative z-10 h-20 max-h-20 bggradient">
    {({ open, close }) => (
      <>
        <div className="h-20 min-w-full my-auto max-h-20">
          <div className="relative w-full h-full my-auto">
            <div className="flex w-full h-full px-4 flex-nowrap">
              <Link className="my-auto mr-auto" to="/">
                <a type="button" className="my-auto mr-auto" href="/">
                  <LogoDark
                    className={
                      props.menuItems
                        ? `m-auto h-14 w-36 ml-6`
                        : `m-auto h-14 w-36 md:ml-6`
                    }
                  />
                </a>
              </Link>
              <Disclosure.Button className="inline-flex items-center justify-center w-auto p-2 text-gray-500 rounded-md md:hidden xs:relative top-3 xs:top-auto xs:right-auto right-4 hover:text-gray-300">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon
                    className="block w-8 h-8 text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <HamburgerMenu className="flex order-2 w-8 h-8 mx-3 text-white my-7" />
                )}
              </Disclosure.Button>
              <div className="hidden md:flex">
                {props?.menuItems && (
                  <>
                    {props.menuItems.map((item) => (
                      <div
                        className="p-3 mx-2 my-auto text-base font-light text-white lg:mx-6 md:text-sm"
                        key={item.name}
                      >
                        {item.ref ? (
                          <button
                            type="button"
                            className=""
                            onClick={() => {
                              item.ref.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              });
                            }}
                          >
                            {item.name}
                          </button>
                        ) : (
                          <a
                            className=""
                            href={item.route ? `#/${item.route}` : item.link}
                          >
                            {item.name}
                          </a>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="relative w-full bggradient h-auto overflow-hidden min-h-page md:hidden bg-newdarkmode-600 z-60">
          <div className="pt-4 pb-3 border-t z-60">
            {props?.menuItems && (
              <>
                {props.menuItems.map((item) => (
                  <div
                    className="p-3 mx-2 my-auto text-base font-light text-white lg:mx-6 md:text-sm"
                    key={item.name}
                  >
                    {item.ref ? (
                      <button
                        type="button"
                        className="mx-12 text-2xl text-white"
                        onClick={() => {
                          item.ref.current.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                          close();
                        }}
                      >
                        {item.name}
                      </button>
                    ) : (
                      <a
                        className="mx-12 my-8 text-2xl text-white"
                        href={item.route ? `#/${item.route}` : item.link}
                      >
                        {item.name}
                      </a>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="absolute inset-x-0 bottom-0 flex flex-row pb-8 dark:text-white">
            <a
              className="w-24 m-auto"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
            >
              <Twitter className="w-10 h-10 m-auto sm:w-10 sm:h-10" />
            </a>
            <a
              className="w-24 m-auto"
              target="_blank"
              rel="noreferrer"
              href="https://t.me/tryDIBA
"
            >
              <Telegram className="m-auto w-14 h-14 sm:w-14 sm:h-14" />
            </a>
            <a
              className="w-24 m-auto"
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/RzKxTJg24K"
            >
              <Discord className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
            </a>
            <a
              className="w-24 m-auto"
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
            >
              <Instagram className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
            </a>
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
export default SecondaryNav;
