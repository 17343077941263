import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import ReactGA from "react-ga";

import { TEST_IDS } from "tests/ids";
import { Location } from "src/types";
import * as backend from "src/Backend";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Copy from "src/Components/Icons/Copy";
import PageWrapper from "src/Components/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Wrappers/PageWrapper2";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import ErrorModal from "src/Components/Modals/Error";
import Modal from "src/Components/Modals/Modal";
import FundWallet from "src/Components/Modals/Children/FundWallet";
import TextField from "src/Components/Inputs/TextField";
import { addBalance, lndhubError } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import getBlinding from "src/lib/getBlinding";
import Loader from "src/Components/Loaders/Loader";

const Receive = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    type,
    locationHistory,
    lnCredentials,
    tokensWalletFunded,
    tokensWalletAddress,
    udasWalletAddress,
  } = location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState({
    description: "",
    amount: 0,
    invoice: "",
  });
  const [conceal, setConceal] = useState("");
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFundWalletModal, setOpenFundWalletModal] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    ReactGA.pageview("Receive");
    Mixpanel.track(`Viewed Receive ${option}`);
  }, []);

  useEffect(() => {
    const getConceal = async () => {
      if (option === "Asset" || option === "UDA") {
        const blinding =
          option === "Asset"
            ? await getBlinding(
                walletData.name,
                vault.rgbAssetsDescriptorXpub,
                true
              )
            : await getBlinding(
                walletData.name,
                vault.rgbUdasDescriptorXpub,
                false
              );
        setConceal(blinding.conceal);
        console.log(blinding.conceal);
      }
    };
    getConceal();
  }, [option]);

  if (option === "") {
    return (
      <PageWrapper2
        className=""
        title="Receive"
        handlePageBack={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials },
          })
        }
        handleCancel={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials },
          })
        }
        button={
          <RoundedButton
            className="w-full text-lg text-black border-2 border-black dark:text-yellow-500 dark:border-yellow-500"
            onClick={() =>
              navigate("/wallet", {
                state: { wallet: walletData.name, vault, lnCredentials },
              })
            }
          >
            Return to wallet
          </RoundedButton>
        }
      >
        <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() => setOption("Bitcoin")}
          >
            Receive Bitcoin on-chain
          </RoundedButton>
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() => setOption("Lightning")}
          >
            Receive Bitcoin Through Lightning
          </RoundedButton>
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() =>
              tokensWalletFunded
                ? setOption("Asset")
                : setOpenFundWalletModal(true)
            }
            // loading={loadingVault}
          >
            Receive Asset
          </RoundedButton>
          <RoundedButton
            className="hidden text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() => setOption("Invoice")}
          >
            Generate Invoice
          </RoundedButton>
        </div>
        <Modal open={openFundWalletModal} setOpen={setOpenFundWalletModal}>
          <FundWallet
            walletBalance={addBalance(walletData?.balance)}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            vault={vault}
            setOpenFundWalletModal={setOpenFundWalletModal}
          />
        </Modal>
      </PageWrapper2>
    );
  }

  if (!conceal && (option === "Asset" || option === "UDA")) {
    return (
      <div className="flex justify-center w-full h-full min-h-screen m-auto text-gray-900 dark:text-gray-300">
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <>
      {option === "Bitcoin" && (
        <PageWrapper2
          className=""
          handlePageBack={() => {
            ReactGA.event({
              category: "Receive",
              action: "wallet bitcoin",
            });
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                lnCredentials,
              },
            });
          }}
          title={`Receive ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault, lnCredentials },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
            <div className="w-auto h-auto py-6 m-auto sm:py-12">
              <QRCode
                value={walletData.address}
                style={{
                  height: "auto",
                  maxWidth: 400,
                  width: "90%",
                  margin: "auto",
                }}
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <h2 className="mt-3 mb-3 text-base font-semibold text-gray-900 sm:mt-6 xs:sm:text-lg dark:text-gray-500">
              Wallet Address
            </h2>
            <button
              type="button"
              className="w-auto max-w-full py-1 xs:py-1.5 my-auto text-gray-700 bg-gray-300 dark:bg-newdarkmode-600 rounded-xl cursor-pointer hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
              onClick={() => {
                Mixpanel.track("Copied Wallet Address", {
                  Source: "Receive Bitcoin",
                });
                navigator.clipboard.writeText(walletData.address);
                setOpen(true);
              }}
              title="Copy wallet address"
            >
              <div className="flex justify-center px-3 flex-nowrap sm:px-6">
                <p className="w-full my-auto text-base font-light truncate">
                  {walletData.address}
                </p>
                <Copy className="my-auto ml-3 w-7 h-7 sm:h-9 sm:w-9 sm:ml-6" />
              </div>
            </button>
          </div>
        </PageWrapper2>
      )}
      {option === "Lightning" && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {lnInvoice?.invoice ? (
            <PageWrapper2
              className=""
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="LN Invoice Created"
              button={
                <RoundedButton
                  className="w-full mr-2 text-base text-black bg-yellow-500"
                  onClick={() =>
                    navigate("/wallet", {
                      state: { wallet: walletData.name, vault, lnCredentials },
                    })
                  }
                >
                  Return to Wallet
                </RoundedButton>
              }
            >
              <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
                <div className="w-auto h-auto py-6 m-auto sm:py-12">
                  <QRCode
                    value={lnInvoice.invoice}
                    style={{
                      height: "auto",
                      maxWidth: 400,
                      width: "90%",
                      margin: "auto",
                    }}
                    size={256}
                    viewBox="0 0 256 256"
                  />
                </div>
              </div>
              <div className="flex flex-col w-full text-gray-800 dark:text-gray-300">
                <p className="w-full mt-3 text-base font-light sm:mt-0 line-clamp-3">
                  <span className="font-medium text-gray-900 dark:text-gray-500">
                    description:
                  </span>{" "}
                  {lnInvoice.description}
                </p>
                <p className="mt-1.5 text-base font-light truncate max-w-64 sm:max-w-152 w-full">
                  <span className="font-medium text-gray-900 dark:text-gray-500">
                    amount:
                  </span>{" "}
                  {lnInvoice.amount.toLocaleString()} SATS
                </p>
                <h2 className="mt-3 mb-3 text-base font-semibold text-gray-900 sm:mt-6 xs:sm:text-lg dark:text-gray-500">
                  Invoice
                </h2>
                <button
                  type="button"
                  className="w-auto max-w-full py-1 xs:py-1.5 my-auto text-gray-700 bg-gray-300 dark:bg-newdarkmode-600 rounded-xl cursor-pointer hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
                  onClick={() => {
                    navigator.clipboard.writeText(lnInvoice.invoice);
                    setOpen(true);
                  }}
                  title="Copy Invoice"
                >
                  <div className="flex justify-center px-3 flex-nowrap sm:px-6">
                    <p className="w-full my-auto text-base font-light truncate">
                      {lnInvoice.invoice}
                    </p>
                    <Copy className="my-auto ml-3 w-7 h-7 sm:h-9 sm:w-9 sm:ml-6" />
                  </div>
                </button>
              </div>
            </PageWrapper2>
          ) : (
            <PageWrapper
              className="font-jetbrains"
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="Create Lightning Invoice"
              handleCancel={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault, lnCredentials },
                })
              }
              handleSubmit={async () => {
                try {
                  await backend.ln_auth(
                    lnCredentials.login,
                    lnCredentials.password
                  );
                  backend
                    .ln_create_invoice(
                      lnInvoice.description,
                      lnInvoice.amount,
                      lnCredentials.accessToken
                    )
                    .then((res) => {
                      if (!res.error) {
                        setLnInvoice({
                          ...lnInvoice,
                          invoice: res.payment_request,
                        });
                      } else {
                        setError({
                          title: "Error Creating LN Invoice",
                          message: lndhubError(res.error),
                        });
                        setOpenError(true);
                      }
                    });
                } catch (err) {
                  if (err instanceof Error) {
                    setError({
                      title: "Error Creating LN Invoice",
                      message: err.message,
                    });
                    setOpenError(true);
                  }
                }
              }}
            >
              <div className="w-full font-jetbrains">
                <TextField
                  type="string"
                  label="description"
                  placeholder="Enter description"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, description: e.target.value })
                  }
                />
                <TextField
                  type="number"
                  label="amount"
                  placeholder="Enter amount"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, amount: e.target.value })
                  }
                />
              </div>
            </PageWrapper>
          )}
        </>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper2
          className="font-jetbrains"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory,
              },
            })
          }
          title={`Receive ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500 font-jetbrains"
              onClick={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto m-auto bg-gray-100 pt-1.5 font-jetbrains">
            <div className="w-auto h-auto py-6 m-auto sm:py-12">
              <QRCode
                value={conceal}
                style={{
                  height: "auto",
                  maxWidth: 400,
                  width: "90%",
                  margin: "auto",
                }}
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <div className="flex flex-col w-full font-jetbrains">
            <h2 className="mt-3 mb-3 text-base font-semibold text-gray-900 sm:mt-6 xs:sm:text-lg dark:text-gray-500">
              Conceal
            </h2>
            <button
              type="button"
              className="w-auto max-w-full py-1.5 my-auto text-gray-700 bg-gray-300 dark:bg-newdarkmode-600 rounded-xl cursor-pointer hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
              onClick={() => {
                Mixpanel.track("Copied Conceal");
                navigator.clipboard.writeText(conceal);
                setOpen(true);
              }}
              title="Copy Conceal"
            >
              <div className="flex justify-center px-3 flex-nowrap sm:px-6">
                <p
                  className="w-full my-auto text-base font-light truncate"
                  data-test-id={TEST_IDS.receive.conceal}
                >
                  {conceal}
                </p>
                <Copy className="my-auto ml-3 w-7 h-7 sm:h-9 sm:w-9 sm:ml-6" />
              </div>
            </button>
          </div>
        </PageWrapper2>
      )}
      {option === "Invoice" && (
        <PageWrapper2
          className="font-jetbrains"
          handlePageBack={() => setOption("")}
          title={`Generate ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500 font-jetbrains"
              onClick={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto m-auto bg-gray-100 pt-1.5 font-jetbrains">
            <div className="w-auto h-auto pb-6 m-auto sm:pb-12">
              <QRCode
                value={walletData.address}
                style={{
                  height: "auto",
                  maxWidth: 400,
                  width: "90%",
                  margin: "auto",
                }}
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <div className="flex flex-col w-full font-jetbrains">
            <div className="flex-grow-default">
              <h2 className="mt-3 mb-3 text-base font-semibold text-gray-900 sm:mt-6 xs:sm:text-lg dark:text-gray-500">
                Wallet Address
              </h2>
            </div>
            <button
              type="button"
              className="w-auto max-w-full py-1 xs:py-1.5 my-auto text-gray-700 bg-gray-300 dark:bg-newdarkmode-600 rounded-xl cursor-pointer hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
              onClick={() => {
                Mixpanel.track("Copied Wallet Address");
                navigator.clipboard.writeText(walletData.address);
                setOpen(true);
              }}
              title="Copy TXID"
            >
              <div className="flex justify-center px-3 flex-nowrap sm:px-6">
                <p className="w-full my-auto text-base font-light truncate">
                  {walletData.address}
                </p>
                <Copy className="my-auto ml-3 w-7 h-7 sm:h-9 sm:w-9 sm:ml-6" />
              </div>
            </button>
          </div>
        </PageWrapper2>
      )}
      <CopiedToClipboard open={open} setOpen={setOpen} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </>
  );
};

export default Receive;
