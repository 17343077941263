/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import Footer from "src/Components/Footer";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Mixpanel from "src/lib/Mixpanel";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";

const NewUser = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);

  ReactGA.pageview("NewUser");
  Mixpanel.track("Viewed Wallet Landing Page");

  return (
    <div className="h-auto overflow-x-hidden overflow-y-auto font-jetbrains min-h-page">
      <div
        className="flex flex-col justify-between w-full h-auto m-auto overflow-hidden"
        style={{
          minHeight: "calc(100vh - 164px",
        }}
      >
        <div className="flex flex-col justify-center w-full h-full p-6 pt-0 m-auto sm:p-10 xl:p-18 ">
          <h1 className="mt-6 text-3xl font-bold text-center text-gray-900 sm:text-4xl md:mb-6 sm:mt-0 lg:text-5xl dark:text-gray-300">
            Let's go!
          </h1>
          <div className="w-full m-auto mt-6 sm:mt-12 lg:mt-auto grid lg:grid-cols-2 grid-cols-1 xl:w-10/12 justify-items-center">
            <div className="flex flex-col items-center w-11/12 h-64 p-0 mb-12 text-center bg-newdarkmode-600 shadow-xl md:p-6 xs:w-88 md:w-112 sm:h-80 rounded-xl lg:mb-0">
              <div className="m-auto">
                {" "}
                <h3 className="text-3xl font-bold text-gray-900 dark:text-gray-300">
                  New to BitMask?
                </h3>
                <RoundedButton
                  className="w-full py-3 mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
                  onClick={() => navigate("/walletsetup")}
                >
                  Create Wallet
                </RoundedButton>
              </div>
            </div>
            <div className="flex flex-col items-center w-11/12 h-64 p-6 mb-12 text-center bg-newdarkmode-600  shadow-xl xs:w-88 md:w-112 sm:h-80 rounded-xl lg:mb-0">
              <div className="m-auto">
                <h3 className="text-3xl font-bold text-gray-900 dark:text-gray-300">
                  Welcome Back!
                </h3>
                <RoundedButton
                  className="w-full py-3 mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
                  onClick={() => navigate("/walletimport")}
                  data-test-id="import-wallet"
                >
                  Import Wallet
                </RoundedButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
