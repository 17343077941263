/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";

import * as backend from "src/Backend";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextField from "src/Components/Inputs/TextField";
import TermsOfService from "src/Components/Modals/Children/TermsOfService";
import Modal from "src/Components/Modals/Modal";
import useWindowSize from "src/Hooks/useWindowSize";
import { GetMnemonicSeed } from "src/types/core";
import { termsOfService } from "src/constants";

const CreatePassword = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [agree, setAgree] = useState(false);
  const [_error, setError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [openTos, setOpenTos] = useState(false);

  const submitPassword = () => {
    props.setSteps([
      { name: "Set Password", href: "#", status: "complete" },
      { name: "Secure Wallet", href: "#", status: "current" },
      { name: "Confirm Secret Backup Phrase", href: "#", status: "upcoming" },
    ]);
    handleChange("secureWallet");
  };

  const handleSubmit = async () => {
    if (agree) {
      const { password, confirmPassword } = props;
      if (password === confirmPassword && password.length > 7) {
        setError(false);

        try {
          // TODO: First argument is the extension encryption password, second argument is seed password
          const { mnemonic, serializedEncryptedMessage }: GetMnemonicSeed =
            await backend.get_mnemonic_seed(password, "");

          window.localStorage.setItem("descriptor", serializedEncryptedMessage);

          console.debug("got mnemonic");

          props.setMnemonic(mnemonic.split(" ").slice(0, 12));

          /* const resGetVault = await callSandbox("get_vault", {
            password,
            encryptedDescriptors: serializedEncryptedMessage,
          });

          if (resGetVault.error) {
            console.error("error in CreatePassword get_vault", resGetVault.error);
            setError(true);
            return;
          }

          await lib.storageSet({ vault: resGetVault.vault });
          console.debug(`Value is set to ${resGetVault.vault}`);
          const vault = JSON.parse(resGetVault.vault);

          props.setVault(vault); */

          submitPassword();
        } catch (error) {
          console.log("error in CreatePassword", error);
          setError(true);
        }
      } else {
        setError(true);
      }
    } else {
      setTermsError(true);
    }
  };

  return (
    <div className="w-full h-auto pt-6 pb-10 m-auto mx-3 text-center  xs:mx-6 sm:m-auto xs:bg-gray-100 sm:shadow-xl sm:w-136 rounded-xl xs:dark:bg-newdarkmode-600 ">
      <Circles steps={props.steps} />
      <h1 className="mt-6 text-3xl font-bold text-center text-gray-900 dark:text-gray-300 flex-grow-default">
        Create Password
      </h1>
      <p className="w-11/12 m-auto mt-2 font-sans text-xl font-normal text-left text-gray-900 xs:w-9/12 dark:text-gray-400 flex-grow-default leading-8">
        Your password allows you to log in to your BitMask account. This is
        different from your seed phrase.
      </p>
      <TextField
        type="password"
        className="w-11/12 p-3 mt-1 xs:w-9/12"
        placeholder="Create Password"
        onChange={(e) => props.setPassword(e.target.value)}
        darkest={size.width >= 500}
      />
      {props.password.length < 8 && props.password !== "" && (
        <div className="w-11/12 m-auto xs:w-9/12">
          <p className="-mb-2 text-sm text-left text-red-600 flex-grow-default">
            Must be 8 characters
          </p>
        </div>
      )}
      <TextField
        type="password"
        className="w-11/12 p-3 mt-2 xs:w-9/12"
        placeholder="Confirm Password"
        onChange={(e) => props.setConfirmPassword(e.target.value)}
        onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        darkest={size.width >= 500}
      />
      {props.password !== props.confirmPassword &&
        props.confirmPassword !== "" && (
          <div className="w-11/12 m-auto xs:w-9/12">
            <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
              passwords must match
            </p>
          </div>
        )}
      {termsError && (
        <div className="w-11/12 m-auto xs:w-9/12">
          <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
            You must agree to the Terms of Service to create an account.
          </p>
        </div>
      )}
      <div className="flex w-11/12 m-auto mt-5 xs:w-9/12 flex-nowrap">
        <input
          type="checkbox"
          className="w-6 h-6 my-auto mr-3 sm:w-5 sm:h-5"
          onChange={() => {
            setAgree(!agree);
            setTermsError(false);
          }}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? () => {
                  setAgree(!agree);
                  setTermsError(false);
                }
              : null
          }
        />
        <p className="my-auto font-sans text-sm font-normal text-left text-gray-900 dark:text-gray-400">
          Click to agree to our terms of service,
          <button
            type="button"
            className="ml-2 text-gray-700 underline dark:text-green-500"
            onClick={() => setOpenTos(true)}
          >
            Terms of Service.
          </button>
        </p>
      </div>
      <RoundedButton
        className="w-9/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
        onClick={() => handleSubmit()}
      >
        <span className="m-auto">Create a new wallet</span>
      </RoundedButton>
      <Modal open={openTos} setOpen={setOpenTos}>
        <TermsOfService tos={termsOfService} />
      </Modal>
    </div>
  );
};

export default CreatePassword;
