/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import { TEST_IDS } from "tests/ids";

import * as backend from "src/Backend";
import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import useWindowSize from "src/Hooks/useWindowSize";
import lib, { isExtension } from "src/lib";
import { GetEncryptedWallet, SaveMnemonicSeed } from "src/types/core";
import ErrorModal from "src/Components/Modals/Error";
import Mixpanel from "src/lib/Mixpanel";

const WalletImport = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [words, setWords] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [seedError, setSeedError] = useState(false);
  const urlParams = useParams();

  useEffect(() => {
    if (urlParams.seed) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
        debug: false,
        gaAddress: "analytics.js",
        gaOptions: {
          cookieDomain: "none",
        },
      });
      ReactGA.ga("set", "checkProtocolTask");
      ReactGA.pageview("WalletImportQR");
      setWords(atob(urlParams.seed));
    } else {
      ReactGA.pageview("WalletImport");
      Mixpanel.track("Viewed Import Wallet");
    }
  }, [urlParams.seed]);

  const handleSubmit = async () => {
    if (password === confirmPassword && password.length > 7) {
      const wallet = "wallet_01";
      // TODO: Second argument is the extension encryption password, third argument is seed password
      try {
        const { serializedEncryptedMessage }: SaveMnemonicSeed =
          await backend.save_mnemonic_seed(words.trim(), password, "");

        window.localStorage.setItem("descriptor", serializedEncryptedMessage);

        const vault: GetEncryptedWallet = await backend.get_encrypted_wallet(
          password,
          serializedEncryptedMessage
        );
        const tokens = await backend.ln_auth(vault.xpubkh, vault.xprvkh);
        ReactGA.event({
          category: "Importing wallet",
          action: "Importing wallet",
        });
        Mixpanel.identify(vault.xpubkh);
        Mixpanel.track("Imported Wallet", { "Wallet ID": vault.xpubkh });

        const reloadScriptAndTabs = () => {
          if (isExtension) {
            chrome.runtime.sendMessage({
              call: "reload_script_and_tabs",
            });
          }
        };

        let refresh = "";
        let token = "";
        const handleWalletCreation = async (rf, tkn) => {
          await lib.storageSet({
            vault,
            lnCredentials: {
              login: vault.xpubkh,
              password: vault.xprvkh,
              refreshToken: rf,
              accessToken: tkn,
            },
          });
          reloadScriptAndTabs();
          navigate("/wallet", {
            state: {
              wallet,
              vault,
              lnCredentials: {
                login: vault.xpubkh,
                password: vault.xprvkh,
                refreshToken: rf,
                accessToken: tkn,
              },
            },
          });
        };
        if ("error" in tokens) {
          if (tokens.error === "UserDoesNotExist") {
            const lnWallet = await backend.ln_create_wallet(
              vault.xpubkh,
              vault.xprvkh
            );
            const retryTokens = await backend.ln_auth(
              vault.xpubkh,
              vault.xprvkh
            );
            if ("error" in retryTokens) {
              setError({
                title: "Error Ocurred on Wallet Import",
                message: retryTokens.error,
              });
              setOpen(true);
            } else {
              refresh = retryTokens.refresh;
              token = retryTokens.token;
              handleWalletCreation(refresh, token);
            }
          } else {
            setError({
              title: "Error Ocurred on Wallet Import",
              message: tokens.error,
            });
            setOpen(true);
          }
        } else {
          refresh = tokens.refresh;
          token = tokens.token;
          handleWalletCreation(refresh, token);
        }
      } catch (resError) {
        if (resError instanceof Error) {
          setError({
            title: resError.name,
            message: resError.message,
          });
          setOpen(true);
        }
        console.error("error in WalletImport", resError);
      }
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="flex font-jetbrains flex-col justify-center h-auto overflow-x-hidden overflow-y-auto  min-h-page">
      <div className="flex flex-col justify-between w-auto h-auto m-auto mx-0 text-center xs:py-8 xs:mx-6 sm:mx-auto bg-newdarkmode-600  sm:w-136 sm:shadow-xl rounded-xl">
        <div className="h-auto px-6 text-center">
          <h1 className="text-3xl font-semibold text-center text-black sm:mt-2  sm:text-4xl dark:text-white">
            Import Wallet
          </h1>
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Create New Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.create}
          />
          {password.length < 8 && password !== "" && (
            <div className="w-10/12 m-auto">
              <p className="text-sm text-left text-red-600 flex-grow-default">
                Must be 8 characters
              </p>
            </div>
          )}
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Confirm Password"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.confirm}
          />
          {password !== confirmPassword && confirmPassword !== "" && (
            <div className="w-10/12 m-auto">
              <p className="text-sm text-left text-red-600 flex-grow-default">
                Passwords must match
              </p>
            </div>
          )}
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Enter Seed Phrase"
            value={words}
            onChange={(e) => setWords(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.mnemonic}
          />
          {!urlParams.seed && (
            <h3 className="mx-3 mb-6 text-base font-light text-gray-700 sm:mx-12 dark:text-gray-300">
              Enter seed phrase in order from start to finish, with a single
              space after each word.
            </h3>
          )}
          {seedError && (
            <span className="text-base text-red-600">
              Incorrect Seed Phrase. Please try again.
            </span>
          )}
          <RoundedButton
            className="w-10/12 py-3 mx-auto mt-4 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
            onClick={() => handleSubmit()}
          >
            Import Wallet
          </RoundedButton>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default WalletImport;
