/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import { Asset, BlindingUtxo, Collection, Location, UDA } from "src/types";
import TextArea from "src/Components/Inputs/TextArea";
import ErrorModal from "src/Components/Modals/Error";
import PageWrapper from "src/Components/Wrappers/PageWrapper";
import lib from "src/lib";
import { updatingAssets } from "src/aux/updateAssets";
import { base64ToBech32 } from "src/Hooks/util";
import {
  AcceptTransaction as AcceptTransactionResponse,
  ImportAsset,
} from "src/types/core";
import Mixpanel from "src/lib/Mixpanel";
import getBlinding from "src/lib/getBlinding";

const AcceptTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { asset, walletData, vault, type, funded, address, locationHistory } =
    location.state;
  const [id, setId] = useState("");
  const [consignment, setConsignment] = useState("");

  const [blinding, setBlinding] = useState({
    utxo: "",
    conceal: "",
    blinding: "",
  });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    const setBlindingProperty = async () => {
      setBlinding(
        await getBlinding(walletData.name, vault.rgbAssetsDescriptorXpub, true)
      );
    };

    // call the function
    setBlindingProperty()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    ReactGA.pageview("Accept Transaction");
    Mixpanel.track("Viewed Accept Asset Transaction", { Asset: asset });
  }, []);

  const handleSubmit = async () => {
    if (consignment.length === 0) {
      console.error("No consignment specified");
      setError({
        title: `Error creating transaction`,
        message: "Consignment field was left empty",
      });
      setOpen(true);
    } else {
      try {
        const accept = await backend.accept_transfer(
          consignment,
          blinding.blinding,
          blinding.utxo
        );

        console.log("accept", accept);

        if (type && type === "UDA") {
          const assetData: ImportAsset = await backend.import_asset(
            id,
            blinding.utxo,
            blinding.conceal
          );

          const udaData: UDA = {
            id: assetData.id,
            name: assetData.name,
            image: "",
            description: "",
            collection: "",
            genesis: assetData.genesis,
            consignment,
            blinding: blinding.blinding,
            utxo: blinding.utxo,
            asset: assetData,
          };
          const collection = ""; // TODO: name collection
          const key = `${walletData.name}.collections`;
          console.log(key);
          chrome.storage.local.get([key], (result) => {
            const futureCollections: Collection[] = [];
            if (result[key] && result[key].length > 0) {
              if (collection !== "") {
                futureCollections.push({
                  name: collection,
                  udas: [udaData],
                });
                result[key].map((item) => {
                  if (item.name === collection) {
                    futureCollections.map((elem) => {
                      if (elem.name === item.name) {
                        // eslint-disable-next-line no-param-reassign
                        elem.udas = [...item.assets, ...elem.udas];
                      }
                    });
                  } else {
                    futureCollections.push(item);
                  }
                });
              } else {
                futureCollections.push({
                  name: "Single Assets",
                  udas: [udaData],
                });
                result[key].map((item) => {
                  if (item.name === "Single Assets") {
                    futureCollections.map((elem) => {
                      if (elem.name === item.name) {
                        // eslint-disable-next-line no-param-reassign
                        elem.udas = [...item.udas, ...elem.udas];
                      }
                    });
                  } else {
                    futureCollections.push(item);
                  }
                });
              }
            } else if (collection !== "") {
              futureCollections.push({
                name: collection,
                udas: [udaData],
              });
            } else {
              futureCollections.push({
                name: "Single Assets",
                udas: [udaData],
              });
            }
            console.log(result);
            chrome.storage.local.set({ [key]: futureCollections }, () => {
              console.log(`Value is set to ${futureCollections}`);
            });
          });
          ReactGA.event({
            category: "Transfer",
            action: "Accepting transfer of an UDA",
            label: id,
          });
          navigate("/success", {
            state: {
              wallet: walletData.name,
              vault,
              consignment,
              transaction: { assetType: "Accepted" },
            },
          });
        } else {
          // TODO: update with genesis
          const assetData: ImportAsset = await backend.import_asset(
            asset.genesis,
            blinding.utxo,
            blinding.conceal
          );
          const futureAssets = await updatingAssets(walletData, assetData);
          console.debug(`Value is set to ${futureAssets}`);
          const key = `${walletData.name}.conceal`;
          await lib.storageDel([key]);
          ReactGA.event({
            category: "Transfer",
            action: "Accepting transfer of an asset",
            label: asset.id,
          });
          navigate("/success", {
            state: {
              wallet: walletData.name,
              vault,
              consignment,
              transaction: { assetType: "Accepted" },
            },
          });
        }
      } catch (err: any) {
        setError({
          title: `Error accepting transaction`,
          message: err.message,
        });
        setOpen(true);
      }
    }
  };

  return (
    <PageWrapper
      title="Accept Transaction"
      handlePageBack={() => {
        ReactGA.event({
          category: "Success",
          action: "Page back",
        });
        navigate(locationHistory.pop()?.replace("#", "") || "/wallet", {
          state: { ...location.state, wallet: walletData.name },
        });
      }}
      handleCancel={() => {
        ReactGA.event({
          category: "Success",
          action: "Cancel",
        });
        navigate("/wallet", { state: { wallet: walletData.name, vault } });
      }}
      handleSubmit={() => handleSubmit()}
    >
      {type && type === "UDA" && (
        <TextArea
          className="w-full p-2 mt-2"
          placeholder="Enter asset ID here"
          rows={3}
          onChange={(e) => setId(e.target.value)}
        />
      )}
      <TextArea
        className="w-full p-2 mb-6"
        placeholder="Enter Consignment"
        label="Consignment"
        onChange={(e: any) => setConsignment(e.target.value)}
      />
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default AcceptTransaction;
