// eslint-disable-next-line arrow-body-style
const logger = (args) => {
  // console.log('HELLO');
  // console.log({ args });
  return args;
};

export const rewriteImageUrl = (uri: string) =>
  logger(
    logger(uri)
      ?.replace(
        /^.*:\/\/diba-nft-assets\.s3\.us-west-1\.amazonaws\.com/,
        logger(process.env.NEXT_PUBLIC_BUCKET_URI)
      )
      .replace(
        /^.*s3\.us-west-1\.amazonaws\.com\/diba-nft-assets/,
        process.env.NEXT_PUBLIC_BUCKET_URI
      )
  );

export const rewriteAssetImageUrl = (asset) => ({
  ...logger(asset),
  imageUrl: rewriteImageUrl(logger(asset.imageUrl)),
  // media: { ...asset.media, link: rewriteImageUrl(logger(asset?.media?.link)) },
});
