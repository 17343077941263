import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ReactGA from "react-ga";

import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import { MainNavContext } from "src/Hooks/MainNavContext";
import useWindowSize from "src/Hooks/useWindowSize";

const Layout = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const { mainNav } = useContext(MainNavContext);
  ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
    debug: false,
    gaAddress: "analytics.js",
    gaOptions: {
      cookieDomain: "none",
    },
  });
  ReactGA.ga("set", "checkProtocolTask");

  useEffect(() => {
    setHeight(size.height - 80);
  }, [mainNav, size]);

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-x-hidden overflow-y-auto font-jetbrains">
      <SecondaryNav
        menuItems={[
          { name: "Deep Web3", route: "/" },
          { name: "Features", route: "/" },
          { name: "Blog", route: "blog" },
          { name: "FAQ", route: "faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          // remove navbar height from page height
          height,
          minHeight: `${mainNav ? "551px" : height}`,
        }}
        className="w-full my-auto dark:bggradient dark:bg-newdarkmode-700"
      >
        <div className="flex flex-col justify-center h-auto min-h-full bggradient sm:pt-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
