/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"; // import BlogPost from "src/Components/Blogs/BlogPost";
import ReactMarkdown from "react-markdown";
import Loader from "src/Components/Loaders/Loader";
import { isExtension } from "src/lib";
import { classNames } from "src/Hooks/util";

import matter from "gray-matter";
import { useParams } from "react-router-dom";

const Blog = () => {
  const [postBlog, setPostBlog] = useState(null);
  const { title } = useParams();
  console.log(title);

  useEffect(() => {
    import(`src/markdown/${title}.md`).then((res) => {
      fetch(res.default)
        .then((response) => response.text())
        .then((response) => {
          console.log(matter(response));
          setPostBlog(matter(response));
        })
        .catch((err) => console.log(err));
    });
  }, []);

  if (!postBlog?.data?.title) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-screen m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradient dark:bg-newdarkmode-700"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="overflow-y-auto h-page font-jetbrains">
      <div className="flex flex-col min-h-screen my-auto mx-6 md:mx-auto max-w-4xl">
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex-grow-default text-white mx-2 md:mx-6 lg:mx-20 p-4 whitespace-pre-wrap leading-8 md:leading-9 text-lg lg:font-xl">
            <h1 className="text-2xl font-thin xs:text-3xl lg:text-4xl text-left font-integral leading-[3.5rem]">
              {postBlog.data.title}
            </h1>
            <div className="my-6 text-base font-normal text-gray-200 whitespace-pre-wrap sm:my-12 xs:text-lg lg:text-xl md:font-light leading-8 md:leading-9">
              <ReactMarkdown
                linkTarget="_blank"
                components={{
                  img: ({ node, ...props }) => (
                    <img className="w-full lg:my-6" {...props} />
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      href={props.href}
                      className="font-normal underline lg:font-light"
                      {...props}
                    />
                  ),
                  h1: ({ node, ...props }) => (
                    <h1
                      className="text-2xl font-light font-integral"
                      {...props}
                    />
                  ),
                  h2: ({ node, ...props }) => (
                    <h2
                      className="text-2xl font-light font-integral"
                      {...props}
                    />
                  ),
                }}
              >
                {postBlog.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
