/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* global chrome */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MainNavContext } from "src/Hooks/MainNavContext";
import Mixpanel from "src/lib/Mixpanel";
import { isExtension } from "src/lib";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Dropdown from "src/Components/Inputs/NetworkDropdown";
import NavbarMenu from "src/Components/Navbar/Menu";
import Refresh from "src/Components/Icons/Refresh";
import { NetworkContext } from "src/Hooks/NetworkContext";
import LogoDark from "src/Components/Icons/LogoDark";

const items = ["Bitcoin Mainnet", "Bitcoin Testnet"];

const MainNav = () => {
  const { network, setNetwork } = useContext(NetworkContext);
  const { setMainNav } = useContext(MainNavContext);
  const navigate = useNavigate();

  return (
    <Disclosure
      as="header"
      className="relative z-10 bg-gray-100 dark:bggradient dark:bg-newdarkmode-700"
    >
      {({ open }) => (
        <div className="min-w-full px-2 max-w-7xl sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
          <div className="relative flex justify-between h-12">
            <div className="relative z-10 flex-grow-default xl:w-1/5 lg:pr-8 md:pr-0 lg:px-0">
              <div className="flex m-auto flex-nowrap">
                <button
                  type="button"
                  onClick={() => window.location.reload()}
                  className="mx-2 my-auto cursor-pointer sm:mx-4"
                >
                  <div className="flex m-auto sm:px-4 flex-nowrap">
                    <LogoDark className="w-32 h-12" />
                  </div>
                </button>
              </div>
            </div>
            <div className="z-0 w-64 px-2 m-auto sm:inset-0">
              <div className="flex w-full">
                <div className="w-auto m-auto">
                  <Dropdown
                    items={items}
                    setSelected={setNetwork}
                    className="inline-flex justify-center w-auto max-w-32 h-9 sm:max-w-full rounded-md border-1 disabled:opacity-50 disabled:cursor-not-allowed border-gray-600 dark:bg-newdarkmode-900 shadow-sm pl-1 pr-3 py-1.5 mt-0.5 bg-gray-100 text-sm font-normal text-gray-800 dark:text-gray-300 hover:bg-gray-100 disabled:hover:bg-gray-100 dark:hover:bg-newdarkmode-600 disabled:dark:hover:bggradient focus:outline-none focus:ring-1 focus:ring-newdarkmode-600"
                    title="Select Network"
                  >
                    <ChevronDownIcon
                      className="w-6 h-6 m-auto"
                      aria-hidden="true"
                    />
                    <span className="m-auto truncate">{network}</span>
                  </Dropdown>
                </div>
                <button
                  type="button"
                  className="m-auto"
                  onClick={() => {
                    Mixpanel.track("Refreshed Wallet");
                    if (isExtension) {
                      navigate("/");
                    } else {
                      window.location.reload();
                    }
                    if (setMainNav) setMainNav(true);
                  }}
                >
                  <Refresh className="w-6 h-6 m-auto text-gray-800 dark:text-gray-400" />
                </button>
                <div title="Toggle Menu" className="float-right my-auto">
                  <NavbarMenu className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default MainNav;
