/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import SuccessIcon from "src/Components/Icons/Success";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { GetEncryptedWallet } from "src/types/core";
import Mixpanel from "src/lib/Mixpanel";

const Success = ({ ...props }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const handleSubmit = async () => {
    const wallet = "wallet_01";
    const encryptedDescriptors = window.localStorage.getItem("descriptor");
    try {
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }
      const vault: GetEncryptedWallet = await backend.get_encrypted_wallet(
        props.password,
        encryptedDescriptors
      );

      await lib.storageSet({ vault });
      // const { vault } = props;
      await backend.ln_create_wallet(vault.xpubkh, vault.xprvkh);
      const tokens = await backend.ln_auth(vault.xpubkh, vault.xprvkh);
      let refresh = "";
      let token = "";
      if ("error" in tokens) {
        setError({
          title: "Error Ocurred on LN Wallet Creation",
          message: tokens.error,
        });
        setOpen(true);
      } else {
        refresh = tokens.refresh;
        token = tokens.token;
        await lib.storageSet({
          vault,
          lnCredentials: {
            login: vault.xpubkh,
            password: vault.xprvkh,
            refreshToken: refresh,
            accessToken: token,
          },
        });
        ReactGA.event({
          category: "Success",
          action: "Going to the wallet",
        });
        Mixpanel.alias(vault.xpubkh);
        Mixpanel.track("Created New Wallet", { "Wallet ID": vault.xpubkh });
        if (isExtension) {
          chrome.runtime.sendMessage({
            call: "reload_script_and_tabs",
          });
        }
        navigate("/wallet", {
          state: {
            wallet,
            vault,
            lnCredentials: {
              login: vault.xpubkh,
              password: vault.xprvkh,
              refreshToken: refresh,
              accessToken: token,
            },
          },
        });
      }
    } catch (err) {
      console.error("Error in SignIn call to get_encrypted_wallet", err);
      if (err instanceof Error) {
        setError({ title: err.name, message: err.message });
        setOpen(true);
        if (err?.toString().toLowerCase().includes("invalid")) {
          console.info("Deleting descriptor, parse error");
          window.localStorage.removeItem("descriptor");
        }
      }
    }
  };

  return (
    <div className="w-full h-auto m-auto overflow-hidden">
      <div className="flex flex-col items-center w-full h-full lg:flex-row lg:flex-nowrap">
        <div className="float-left w-full lg:w-6/12">
          <div className="px-12 text-center xs:mt-8 grid grid-cols-1 sm:px-20 lg:mt-0 lg:text-left">
            <h1 className="text-3xl font-bold text-gray-900  sm:text:4xl md:text-5xl lg:text-left dark:text-gray-300">
              Congratulations!
            </h1>
            <p className="max-w-6xl m-auto my-6 text-lg font-normal text-left text-gray-900 sm:text-xl dark:text-gray-400">
              You have your very own BitMask wallet! Get ready for all of the
              incredible adventures to come. Get started now by opening the
              BitMask extension on your web browser. Or by using the BitMask Web
              Wallet at{" "}
              <a className="underline" href="https://bitmask.app">
                bitmask.app
              </a>
              .
            </p>

            <RoundedButton
              className="w-7/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl lg:mx-0"
              onClick={() => handleSubmit()}
            >
              Launch
            </RoundedButton>
          </div>
        </div>
        <div className="flex w-full h-full my-12 lg:w-6/12 lg:pr-12">
          <div className="m-auto rounded-full h-72 w-72 lg:h-full lg:w-full">
            <div className="m-auto">
              <SuccessIcon className="w-auto h-auto m-auto" />
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default Success;
