import { bech32, base64url } from "@scure/base";

import { WalletBalance } from "src/types";

export const getConversion = async () => {
  const res = await fetch("https://api.coingecko.com/api/v3/exchange_rates");
  const data = await res.json();
  return data.rates.usd.value;
};

export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(" ");

interface Props {
  [k: string]: unknown;
}

export const omitKeys = (props: Props, keys: string[]) =>
  Object.fromEntries(Object.entries(props).filter(([k]) => !keys.includes(k)));

export const bech32ToBase64 = (b32: string) => {
  const { bytes } = bech32.decodeToBytes(b32);
  const b64 = base64url.encode(bytes);

  console.log(
    "bech32:",
    b32.length,
    "base64:",
    b64.length,
    "reduction factor:",
    (b32.length - b64.length) / b64.length
  );

  return b64;
};

export const base64ToBech32 = (prefix: string, b64: string) =>
  bech32.encode(prefix, bech32.toWords(base64url.decode(b64)), false);

export const addBalance = (balance: WalletBalance | undefined) =>
  balance ? Object.entries(balance).reduce((acc, [, v]) => acc + v, 0) : 0;

export const delay = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, ms));

export const lndhubError = (errorCode) => {
  switch (errorCode) {
    case "DepositLimitExceeded":
      return "Deposit Limit Exceeded";
    case "InvalidInvoice":
      return "Invalid Invoice";
    case "InsufficientFundsForFees":
      return "Insufficient Funds For Fees";
    default:
      return errorCode;
  }
};
