/* eslint-disable no-unused-vars */
import React from "react";
import { RadioGroup } from "@headlessui/react";

const options = [
  {
    name: "General",
  },
  {
    name: "Getting Started",
  },
  {
    name: "Managing My Digital Assets",
  },
  {
    name: "Payments",
  },
  {
    name: "Security",
  },
  {
    name: "Troubleshooting",
  },
];

const SideOptions = ({ ...props }) => (
  <div className="mx-auto w-11/12 sm:w-full pb-12 sm:px-30">
    <RadioGroup value={props.value} onChange={props.setValue}>
      <RadioGroup.Label className="sr-only">options</RadioGroup.Label>
      <div className="shadow-lg dark:bg-newdarkmode-600 dark:border-1 dark:border-darkmode-400 dark:border-opacity-15 rounded-xl  divide-y-1 divide-darkmode-400 divide-opacity-50">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ active, checked }) =>
              `relative px-5 py-4 cursor-pointer flex focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <div className="flex items-center mx-auto sm:justify-between sm:w-full">
                <div className="flex items-center">
                  <div>
                    <RadioGroup.Label
                      as="div"
                      className={`font-medium flex flex-nowrap text-center m-auto hover:text-yellow-200 ${
                        props.value.name === option.name
                          ? "text-yellow-500"
                          : "text-gray-200"
                      }`}
                    >
                      <p className="pr-4 m-auto text-base xl:text-lg text-center sm:text-left">
                        {option.name}
                      </p>
                    </RadioGroup.Label>
                  </div>
                </div>
                {checked && <div className="flex-shrink-0 text-white" />}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </div>
);

export default SideOptions;
