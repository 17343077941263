/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import PageWrapper3 from "src/Components/Wrappers/PageWrapper3"; // @ts-ignore: no-any
import { ImportAsset } from "src/types/core";
import FullScreenModal from "src/Components/Modals/FullScreenModal";
import { Menu, Transition } from "@headlessui/react";
import getBlinding from "src/lib/getBlinding";
import Dots from "src/Components/Icons/Dots";
import PageWrapper2 from "src/Components/Wrappers/PageWrapper2";
import FullScreen from "src/Components/Icons/FullScreen";
import XMarkIcon from "src/Components/Icons/XMarkIcon";

const UniqueDigitalAsset = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault, uda, locationHistory } = location.state;
  const [openFullScreenModal, setFullScreenModal] = useState(false);

  const items = [
    {
      name: "Send",
      disabled: true,
    },
    {
      name: "Burn",
      disabled: true,
    },
  ];

  const setAssetInUda = async () => {
    const blinding = await getBlinding(
      walletData.name,
      vault.rgbUdasDescriptorXpub,
      false
    );
    uda.asset = await backend.import_asset(
      uda.id,
      blinding.utxo,
      blinding.conceal
    );
  };

  useEffect(() => {
    if (!uda.asset) setAssetInUda();
  }, []);

  /*
          <div className="flex m-auto text-left flex-nowrap">
          <img
            key={uda.publisher.username}
            src={uda.publisher.profile.profileImage}
            className="object-cover w-24 h-24 m-auto rounded-lg lg:w-44 lg:h-44 "
            alt="DIBA profile avatar of the UDA publisher"
          />
          <p className="h-auto overflow-hidden text-base font-light text-black md:text-lg lg:text-xl dark:text-white overflow-ellipsis">
            {uda?.publisher?.username}
          </p>
        </div>
  */

  return (
    <PageWrapper2
      handlePageBack={() => {
        navigate("/wallet", { state: { wallet: walletData.name, vault } });
      }}
      className="font-jetbrains"
      title="View UDA"
      button={null}
    >
      <div className="relative mx-auto py-6 w-full bg-gray-300 dark:bg-newdarkmode-900 z-0">
        <img
          key={uda.id}
          src={uda.image}
          className="object-cover w-full h-full m-auto md:w-112 md:h-112"
          alt="unique digital asset"
        />
        <button
          className="absolute bottom-4 text-black dark:text-white hover:scale-[105%] duration-300 right-4 h-7 w-7 z-20"
          type="button"
          onClick={() => {
            setFullScreenModal(true);
          }}
        >
          <FullScreen />
        </button>
      </div>
      <div className="h-auto w-full my-5 font-jetbrains">
        <div className="flex flex-row my-auto">
          <h1 className="my-3 mx-6 text-2xl font-semibold text-black lg:text-3xl dark:text-white">
            {uda.name}
          </h1>
          <div className="ml-auto flex flex-row my-auto mr-8 gap-3">
            <h1 className="my-auto ml-auto text-lg font-light text-black lg:my-6 lg:text-xl dark:text-white order-2">
              CarterOnline
            </h1>
            <img
              src="images/CarterOnline_lance_armstrong_cosmic_concert_3a6edb87-ffde-49ba-86a0-b65d30854dc1.png"
              className="w-8 h-8 mr-2 my-auto rounded-full order-1"
              alt="profile avatar"
            />
            <button className="order-3 my-auto" type="button">
              <Dots className="h-7 w-7 rotate-90 text-gray-800 dark:text-gray-400 my-auto ml-auto" />
            </button>
          </div>
        </div>
        <p className="h-auto mx-6 overflow-hidden text-base font-light text-left text-black md:text-lg md:font-light dark:text-white overflow-ellipsis line-clamp-3 pb-6">
          {uda.description}
        </p>
      </div>
      {/* <div className="m-auto">
        <RoundedButton
          className="px-6 m-auto mt-6 mb-12 text-base text-black bg-yellow-500"
          onClick={() => {
            if (uda.asset) {
              // TODO: must be to wait until uda.asset is there
              ReactGA.event({
                category: "Asset details",
                action: "Send",
                label: uda.id,
              });
              navigate("/send", {
                state: {
                  ...location.state,
                  locationHistory: [...locationHistory, window.location.hash],
                  walletData,
                  vault,
                  asset: uda.asset,
                  type: "UDA",
                },
              });
            }
          }}
        >
          Send UDA
        </RoundedButton>
      </div> */}
      <FullScreenModal
        open={openFullScreenModal}
        setOpen={setFullScreenModal}
        uda={uda.image}
      />
    </PageWrapper2>
  );
};

export default UniqueDigitalAsset;
