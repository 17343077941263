/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import getBlinding from "src/lib/getBlinding";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import ReactGA from "react-ga";

import { TEST_IDS } from "tests/ids";

import * as backend from "src/Backend";
import { NetworkContext } from "src/Hooks/NetworkContext";
import { MainNavContext } from "src/Hooks/MainNavContext";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Tabs from "src/Components/Tabs/Tabs";
import ArrowUpCircle from "src/Components/Icons/ArrowUpCircle";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Modal from "src/Components/Modals/Modal";
import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Loader from "src/Components/Loaders/Loader";
import { getConversion, classNames, addBalance } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import lib from "src/lib";
import FundWallet from "src/Components/Modals/Children/FundWallet";
import { Activity, Asset, Collection, Location, WalletData } from "src/types";
import { GetWalletData, ImportAsset, LnWalletData } from "src/types/core";
import { releaseNotes } from "src/constants";
import Lightning from "src/Components/Icons/Lightning";

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { network } = useContext(NetworkContext);
  const { setMainNav } = useContext(MainNavContext);
  const [tokensWalletFunded, setTokensWalletFunded] = useState(false);
  const [loadingVault, setLoadingVault] = useState(false);
  const [lnWalletData, setLnWalletData] = useState<LnWalletData>(
    localStorage.getItem("lnWalletData")
      ? JSON.parse(localStorage.getItem("lnWalletData") || "")
      : []
  );
  const [tokensWalletAddress, setTokensWalletAddress] = useState("");
  const [udasWalletAddress, setUdasWalletAddress] = useState("");
  const [openFundWalletModal, setOpenFundWalletModal] = useState(false);
  const [view, setView] = useState(0);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const [walletData, setWalletData] = useState<WalletData>(
    localStorage.getItem("walletData")
      ? JSON.parse(localStorage.getItem("walletData") || "")
      : []
  );
  const [conversion, setConversion] = useState(0);
  const [activities, setActivities] = useState<Activity[]>(
    localStorage.getItem("transactionHistory")
      ? JSON.parse(localStorage.getItem("transactionHistory") || "")
      : []
  );
  const [assets, setAssets] = useState<Asset[]>(
    localStorage.getItem("assets")
      ? JSON.parse(localStorage.getItem("assets") || "")
      : []
  );
  const [collections, setCollections] = useState<Collection[]>(
    localStorage.getItem("udas")
      ? JSON.parse(localStorage.getItem("udas") || "")
      : []
  );
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { wallet, vault, lnCredentials } = location.state;
  const { VERSION } = process.env;

  if (
    !location.state ||
    !location.state.vault ||
    !location.state.wallet ||
    !location.state.lnCredentials
  ) {
    console.log("no vault, redirecting back to welcome screen");
    navigate("/", { replace: true });
  } else if (window.location.href.includes("get_utxo")) {
    const params = window.location.href
      .split("?")[1]
      .split("#")[0]
      .split("&")
      .map((e) => e.split("=")[1]);
    navigate("/authorizetransaction", {
      replace: true,
      state: {
        message: params[0],
        tabid: parseInt(params[1] as string, 10),
        pubkeyHash: params[2],
        id: params[3],
        uid: params[4],
      },
    });
  } else if (
    window.location.href.includes("get_vault") ||
    window.location.href.includes("pass_asset")
  ) {
    const params = window.location.href
      .split("?")[1]
      .split("#")[0]
      .split("&")
      .map((e) => e.split("=")[1]);
    console.log(params);
    console.log("we are in connect window");
    navigate("/confirmconnect", {
      replace: true,
      state: {
        message: params[0],
        tabid: parseInt(params[1] as string, 10),
        pubkeyHash: params[2],
        id: params[3],
        uid: params[4],
      },
    });
  }

  const getPage = (param) => {
    switch (param) {
      case 0:
        return "Activities";
      case 1:
        return "Assets";
      case 2:
        return "UDAs";
      default:
        return "Activities";
    }
  };
  ReactGA.pageview("Wallet");

  const getLigntningWalletData = async () => {
    const lnAccount = await backend.ln_get_balance(lnCredentials.accessToken);
    const lnTxs = await backend.ln_get_txs(lnCredentials.accessToken);
    const lnActivitiesData = lnTxs.map((transaction) => {
      let date = Math.round(Date.now() / 1000);
      if (transaction.created_at !== null)
        date = Number(transaction.created_at) / 1000;
      const activity: Activity = {
        ...transaction,
        lightning: true,
        date,
        id: transaction.txid,
        assetType: "sats",
        amount:
          (Number(transaction.outbound_amount) - Number(transaction.fees)) *
          100_000_000,
        fee: Number(transaction.fees) * 100_000_000,
        action:
          transaction.outbound_account_id === lnAccount[0].account_id
            ? "sent"
            : "received",
        status: "confirmed",
      };
      return activity;
    });
    localStorage.setItem(
      "lnWalletData",
      JSON.stringify({ balance: lnAccount[0], transactions: lnTxs })
    );
    setLnWalletData({ balance: lnAccount[0], transactions: lnTxs });
    return lnActivitiesData;
  };

  const getWalletData = async () => {
    const result = await lib.storageGet<WalletData>(["wallet"]);
    setWalletData(result.wallet);
    const { btcDescriptorXprv, btcChangeDescriptorXprv } = vault;
    console.debug({ btcDescriptorXprv, btcChangeDescriptorXprv });
    try {
      const response: GetWalletData = await backend.get_wallet_data(
        btcDescriptorXprv,
        btcChangeDescriptorXprv
      );
      const data = { ...response, name: wallet };
      Mixpanel.register_once({
        "Wallet ID": vault.xpubkh,
      });
      Mixpanel.register({
        "Number of Lifetime Transactions": data.transactions.length,
        "Lifetime Transaction Volume": data.transactions
          .map((transaction) => {
            if (transaction.sent === 0) {
              return transaction.received;
            }
            return transaction.sent - transaction.received + transaction.fee;
          })
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ),
      });
      setWalletData(data);
      await lib.storageSet({ wallet: data });
      console.debug(`Value is set to ${JSON.stringify(data)}`);
      localStorage.setItem("walletData", JSON.stringify(data));
      const key = `${wallet}.transactions`;
      const lnActivities = await getLigntningWalletData();
      const layerOneActivitiesData = data.transactions.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.confirmationTime !== null)
          date = transaction.confirmationTime.timestamp;
        const activity: Activity = {
          ...transaction,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            transaction.sent === 0
              ? transaction.received
              : transaction.sent - transaction.fee,
          fee: transaction.fee,
          action: transaction.sent === 0 ? "received" : "sent",
          status: transaction.confirmed ? "confirmed" : "pending",
        };
        return activity;
      });
      const sortedActivities = [
        ...lnActivities,
        ...layerOneActivitiesData,
      ].sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
      setActivities(sortedActivities);
      await lib.storageSet({ [key]: sortedActivities });
      localStorage.setItem(
        "transactionHistory",
        JSON.stringify(sortedActivities)
      );
      console.debug(`Value is set to ${JSON.stringify(sortedActivities)}`);
    } catch (error) {
      console.error("error in Wallet get_wallet_data", error);
    }
  };

  const getNewAssets = async () => {
    const key = `${wallet}.assets`;
    const result = await lib.storageGet<Asset[]>([key]);

    if (result[key]) {
      const futureAssets = result[key];
      setAssets(futureAssets);
      try {
        const blinding = await getBlinding(
          walletData.name,
          vault.rgbAssetsDescriptorXpub,
          true
        );
        const newAssets: ImportAsset[] = await Promise.all(
          futureAssets.map((asset) =>
            backend.import_asset(asset.id, blinding.utxo, blinding.conceal)
          )
        );

        setAssets(newAssets);
        await lib.storageSet({ [key]: newAssets });
      } catch (err) {
        console.log("error in Wallet import_asset", err);
      }
    }
  };

  const setTokensWallet = async () => {
    setLoadingVault(true);
    const { rgbAssetsDescriptorXpub } = vault;
    console.debug({ rgbAssetsDescriptorXpub });
    try {
      // setLoadingVault(true);
      const walletDataTokens: GetWalletData = await backend.get_wallet_data(
        rgbAssetsDescriptorXpub
      );
      setTokensWalletAddress(walletDataTokens.address);
      await lib.storageSet({ unspents: walletDataTokens.utxos });
      console.debug(`Value is set to ${walletDataTokens.utxos}`);
      if (walletDataTokens?.utxos?.length < 2) {
        setLoadingVault(false);
        setTokensWalletFunded(false);
        await lib.storageSet({ funded: false });
      } else {
        setLoadingVault(false);
        setTokensWalletFunded(true);
        await lib.storageSet({ funded: true });
      }
    } catch (err) {
      setLoadingVault(false);
      console.error("error in Wallet get_tokens_wallet_data", err);
    }
  };

  const setFutureCollections = async () => {
    const key = `${wallet}.collections`;
    const result = await lib.storageGet<Collection[]>([key]);

    if (result[key]) {
      const futureCollections = result[key];
      setCollections(futureCollections);
      localStorage.setItem("udas", JSON.stringify(futureCollections));
    }
  };

  const setUdasWallet = async () => {
    const { rgbUdasDescriptorXpub } = vault;
    console.debug({ rgbUdasDescriptorXpub });

    try {
      const walletDataNfts: GetWalletData = await backend.get_wallet_data(
        rgbUdasDescriptorXpub
      );
      setUdasWalletAddress(walletDataNfts.address);
      await lib.storageSet({ unspentsForUdas: walletDataNfts.utxos });
      console.debug(`Value is set to ${walletDataNfts.utxos}`);
    } catch (err) {
      console.error("error in Wallet get_nfts_wallet_data", err);
    }
  };

  const getBtcPrice = async () => {
    const rate = await getConversion();
    setConversion(rate);
  };

  useEffect(() => {
    Mixpanel.track("Viewed Wallet", { Page: getPage(view) });
  }, [view]);

  const lnAuth = async () => {
    await backend.ln_auth(lnCredentials.login, lnCredentials.password);
  };

  useEffect(() => {
    if (
      lnCredentials?.login?.length > 0 ||
      lnCredentials?.password?.length > 0
    ) {
      lnAuth();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(vault).length) {
      const walletInfo = localStorage.getItem("walletData");
      const lnWalletInfo = localStorage.getItem("lnWalletData");
      const transactionHistory = localStorage.getItem("transactionHistory");
      const localAssets = localStorage.getItem("assets");
      const udas = localStorage.getItem("udas");
      if (walletInfo) {
        setWalletData(JSON.parse(walletInfo));
        if (JSON.parse(walletInfo).utxos.length) {
          setLoadingVault(false);
          setTokensWalletFunded(true);
        } else {
          setTokensWalletFunded(false);
        }
      }
      if (transactionHistory) setActivities(JSON.parse(transactionHistory));
      if (udas) setCollections(JSON.parse(udas));
      if (localAssets) setAssets(JSON.parse(localAssets));
      if (lnWalletInfo) setLnWalletData(JSON.parse(lnWalletInfo));
      getWalletData();
      getNewAssets();
      setFutureCollections();
      setTokensWallet();
      setUdasWallet();
      getBtcPrice();
      if (!localStorage.getItem(`newVersionInfoPopup${VERSION}`)) {
        localStorage.setItem(`newVersionInfoPopup${VERSION}`, "true");
        setOpenVersionPopup(true);
      }
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (setMainNav) setMainNav(true);
  });

  if (walletData?.balance) {
    return (
      <div className="flex font-jetbrains flex-col w-full mx-auto overflow-hidden text-gray-900 h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12 dark:text-gray-300">
        <div ref={ref} className="flex flex-col justify-center w-full h-auto">
          <p className="mt-4 mb-1 text-sm font-normal text-center text-black dark:text-green-500">
            {walletData?.name}
          </p>
          <button
            type="button"
            title="Copy wallet address"
            onClick={() => {
              navigator.clipboard.writeText(walletData?.address || "");
              setOpenCopyModal(true);
              Mixpanel.track("Copied Wallet Address", { Source: "Home Page" });
            }}
            className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 bg-gray-300 dark:bg-newdarkmode-600 font-thin cursor-pointer text-xs max-w-9/12 text-center w-auto m-auto flex flex-nowrap text-gray-800 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400"
          >
            <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
              {walletData?.address}
            </p>
            <Copy className="flex flex-none w-6 h-6" />
          </button>
          <div className="w-10/12 m-auto">
            <h1 className="mt-2 text-2xl font-bold text-center text-black dark:font-semibold dark:text-yellow-500 flex-grow-default">
              {addBalance(walletData?.balance).toLocaleString()}{" "}
              {network === "Bitcoin Testnet" ? "tSats" : "Sats"}
            </h1>
            <div className="grid grid-cols-2">
              <p className="my-2 text-sm font-light text-center text-black flex-grow-default dark:text-gray-400">
                {Number(
                  addBalance(walletData?.balance) / 100_000_000
                ).toLocaleString(undefined, { minimumFractionDigits: 8 })}{" "}
                {network === "Bitcoin Testnet" ? "tBTC" : "BTC"}
              </p>
              <p className="my-2 text-sm font-light text-center text-black flex-grow-default dark:text-gray-400">
                {Number(
                  (addBalance(walletData?.balance) / 100_000_000) * conversion
                ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
                USD
              </p>
            </div>
          </div>
          {lnWalletData.balance?.balance && (
            <p className="my-2 text-sm font-light text-center text-black flex-grow-default dark:text-gray-400">
              LN wallet:{" "}
              {Number(lnWalletData.balance.balance).toLocaleString(undefined, {
                minimumFractionDigits: 8,
              })}{" "}
              {network === "Bitcoin Testnet" ? "tBTC" : "BTC"} (
              {(
                Number(lnWalletData.balance.balance) * 100_000_000
              ).toLocaleString()}{" "}
              {network === "Bitcoin Testnet" ? "tSats" : "Sats"})
            </p>
          )}
          <div className="w-9/12 m-auto my-4 grid grid-cols-2">
            <RoundedButton
              className="mr-2 text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500"
              onClick={() => {
                Mixpanel.track("Tapped Send Bitcoin", {
                  Source: "Main Wallet",
                });
                ReactGA.event({
                  category: "Wallet",
                  action: "send",
                });
                navigate("/send", {
                  state: {
                    locationHistory: [window.location.hash],
                    type: "",
                    walletData,
                    vault,
                    lnCredentials,
                    asset: {
                      allocations: [],
                      balance: addBalance(walletData?.balance),
                      dollarBalance: (
                        addBalance(walletData?.balance) * conversion
                      ).toFixed(3),
                      id: "",
                      name: "Satoshis",
                      ticker: "Sats",
                    },
                  },
                });
              }}
            >
              Send
            </RoundedButton>
            <RoundedButton
              className="ml-2 text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500"
              onClick={() => {
                Mixpanel.track("Tapped Receive Bitcoin", {
                  Source: "Main Wallet",
                });
                navigate("/receive", {
                  state: {
                    walletData,
                    vault,
                    lnCredentials,
                    locationHistory: [window.location.hash],
                    tokensWalletFunded,
                    loadingVault,
                    tokensWalletAddress,
                    udasWalletAddress,
                  },
                });
              }}
            >
              Receive
            </RoundedButton>
          </div>
          <Tabs
            view={view}
            setView={setView}
            className="mt-2"
            assetsLen={assets.length}
            activitiesLen={activities.length}
            collectionsLen={collections.length}
          />
        </div>
        {view === 0 && (
          <>
            {activities.length ? (
              <div className="flex flex-col w-full h-full m-auto overflow-y-auto flex-grow-default scrollbar dark:darkscrollbar bg-transparent">
                <div className="w-full h-auto px-6 mx-auto bg-transparent">
                  {activities.map((activity) => (
                    <button
                      key={activity.id}
                      className="flex w-full p-2 my-4 border-gray-300 rounded-lg shadow-md border-1/2 dark:border-newdarkmode-600 hover:bg-gray-100 dark:hover:bg-newdarkmode-600 flex-nowrap"
                      type="button"
                      onClick={() =>
                        navigate("/viewtransaction", {
                          state: {
                            walletData,
                            transaction: activity,
                            vault,
                            conversion,
                            locationHistory: [window.location.hash],
                          },
                        })
                      }
                      data-test-id={TEST_IDS.wallet.activity(activity.id)}
                    >
                      <div className="text-left grid grid-cols-1">
                        <div className="flex flex-row">
                          <div className="mr-1.5">
                            {activity?.lightning ? (
                              <Lightning className="w-4 h-4" />
                            ) : (
                              <div className="my-auto text-sm font-semibold text-black dark:text-green-500">
                                L1
                              </div>
                            )}
                          </div>
                          <div className="my-auto text-xs font-light text-black dark:text-gray-500">
                            {new Date(activity.date * 1000).getMonth() + 1}/
                            {new Date(activity.date * 1000).getDate()}/
                            {new Date(activity.date * 1000).getFullYear()}
                          </div>
                        </div>
                        <div className="flex mt-1 text-base font-semibold text-white flex-nowrap">
                          {activity.action === "sent" ? (
                            <ArrowUpCircle className="w-8 h-8 m-auto text-yellow-500" />
                          ) : (
                            <ArrowDownCircle className="w-8 h-8 m-auto text-yellow-500" />
                          )}{" "}
                          <div className="my-auto ml-2 text-base font-normal text-black dark:text-gray-300">
                            {activity.action} {activity.amount.toLocaleString()}{" "}
                            {activity.assetType}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col my-auto ml-auto text-right">
                        <span
                          className={classNames(
                            activity.status === "success"
                              ? "dark:text-green-500"
                              : "dark:text-yellow-500",
                            "text-xs font-normal dark:font-light text-black mb-2"
                          )}
                        >
                          {activity.status}
                        </span>
                        <span className="text-base font-normal text-gray-600">
                          {activity.fee.toLocaleString()} {activity.assetType}
                        </span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center w-full h-full m-auto overflow-y-auto flex-grow-default scrollbar dark:darkscrollbar bg-transparent">
                <div className="flex flex-col justify-center w-full h-full">
                  <h3 className="p-6 text-base text-center text-gray-600 sm:text-lg">
                    No activities in wallet
                  </h3>
                </div>
              </div>
            )}
          </>
        )}
        {view === 1 && (
          <>
            {assets.length ? (
              <div className="flex flex-col h-full my-auto overflow-y-auto flex-grow-default scrollbar dark:darkscrollbar bg-transparent">
                <div className="w-full h-auto px-6 mx-auto bg-transparent">
                  {assets.map((asset) => (
                    <button
                      key={asset.id}
                      type="button"
                      onClick={() =>
                        navigate("/assetdetails", {
                          state: {
                            walletData,
                            vault,
                            asset,
                            funded: tokensWalletFunded,
                            address: tokensWalletAddress,
                            locationHistory: [window.location.hash],
                          },
                        })
                      }
                      onKeyDown={(e) =>
                        e.key === "Enter"
                          ? navigate("/assetdetails", {
                              state: {
                                walletData,
                                vault,
                                asset,
                                funded: tokensWalletFunded,
                                address: tokensWalletAddress,
                                locationHistory: [window.location.hash],
                              },
                            })
                          : null
                      }
                      data-test-id={TEST_IDS.wallet.asset(asset.ticker)}
                      className="w-full p-3 my-4 text-gray-600 border-gray-300 shadow-md grid grid-cols-2 border-1/2 hover:shadow-lg dark:border-darkmode-700 hover:bg-gray-100 dark:hover:bg-newdarkmode-600 dark:text-gray-500 hover:text-black dark:hover:text-white bg-transparent rounded-md"
                    >
                      <div className="w-full mr-auto text-left col-span-1 gap-6">
                        <div className="text-base font-semibold text-gray-900 truncate dark:text-gray-300">
                          {asset.ticker}
                        </div>
                        {/* It's impossible to know the value of an asset without a market.
                            <div className="m-auto text-sm text-black  dark:text-gray-500">
                              {asset.dollarBalance
                                ? `$ ${asset.dollarBalance.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 3,
                                    }
                                  )}USD`
                                : "value unknown"}
                                  </div> */}
                      </div>
                      <div className="flex flex-row w-full my-auto ml-auto col-span-1">
                        <div className="w-auto ml-auto text-base font-normal text-right text-gray-900 truncate flex-shrink-default dark:text-gray-300">
                          {asset.balance?.toLocaleString()} {asset.ticker}
                        </div>
                        <ChevronLeft className="flex-initial w-5 h-5 my-auto ml-3 rotate-180" />
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center w-full h-full m-auto overflow-y-auto flex-grow-default scrollbar dark:darkscrollbar bg-transparent">
                <div className="flex flex-col justify-center w-full h-full">
                  <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                    No assets in wallet
                  </p>
                  <p className="p-6 pt-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                    Click import assets to add assets to wallet
                  </p>
                </div>
              </div>
            )}
            <div
              className={classNames(
                !tokensWalletFunded || loadingVault
                  ? "flex justify-center"
                  : "grid grid-cols-12 gap-6",
                "h-auto m-auto py-2 text-center border-gray-300 border-t-1/2 dark:border-newdarkmode-600 w-full px-6 lg:px-12 flex justify-center"
              )}
            >
              {loadingVault ? (
                <Loader className="w-8 h-8 m-auto" />
              ) : (
                <>
                  {tokensWalletFunded ? (
                    <>
                      <RoundedButton
                        className={classNames(
                          "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                        )}
                        onClick={() => {
                          Mixpanel.track("Tapped Import Asset");
                          navigate("/importassets", {
                            state: { walletData, vault },
                          });
                        }}
                      >
                        <div className="flex flex-row m-auto">
                          <span className="m-auto mr-1.5 text-lg font-bold">
                            +
                          </span>
                          <div className="m-auto">
                            Import{tokensWalletFunded && " Assets"}
                          </div>
                        </div>
                      </RoundedButton>
                      <RoundedButton
                        className={classNames(
                          "sm:px-2 py-1 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                        )}
                        onClick={() => {
                          Mixpanel.track("Tapped Import Asset");
                          navigate("/createasset", {
                            state: { walletData, vault },
                          });
                        }}
                      >
                        <div className="flex flex-row m-auto">
                          <span className="m-auto mr-1.5 text-lg font-bold">
                            +
                          </span>
                          <div className="m-auto">
                            Create{tokensWalletFunded && " Asset"}
                          </div>
                        </div>
                      </RoundedButton>
                    </>
                  ) : (
                    <RoundedButton
                      className="w-auto m-auto text-sm text-black border-gray-700 border-1 sm:px-2 col-span-4 dark:border-yellow-500 dark:text-yellow-500"
                      onClick={() => {
                        // If we don't have the necessary utxas in assets wallet, we say the user tu fund it
                        Mixpanel.track("Tapped Fund Vault");
                        setOpenFundWalletModal(true);
                      }}
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto"> Fund Vault</div>
                      </div>
                    </RoundedButton>
                  )}
                </>
              )}
            </div>
          </>
        )}
        {view === 2 && (
          <>
            <div className="flex flex-col h-full overflow-y-auto flex-grow-default">
              {collections.length ? (
                <div className="w-full h-full overflow-y-auto scrollbar dark:darkscrollbar bg-transparent">
                  <div className="w-full h-auto pl-6 pr-3">
                    {collections.map((collection) => (
                      <Disclosure key={collection.name} as="div" className="">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full p-3 my-4 text-left rounded-lg border-1/2 bg-gray-200 border-gray-350 dark:border-newdarkmode-800 hover:bg-gray-300 dark:bg-newdarkmode-700 dark:hover:bg-newdarkmode-600 flex-nowrap">
                              <img
                                src="https://picsum.photos/200/300"
                                className="object-cover w-10 h-10 m-auto rounded-full"
                                alt="unique digital asset"
                              />
                              <span className="m-auto ml-2 text-base font-bold truncate flex-grow-default">
                                {collection.name?.toUpperCase()}
                              </span>
                              <span className="pl-3 m-auto ml-auto mr-3 text-sm font-thin text-right text-gray-500 whitespace-nowrap">
                                {collection.udas.length} asset
                                {collection.udas.length > 1 ? "s" : ""}
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "transform rotate-180"
                                    : "transform rotate-90"
                                } w-6 h-6 text-gray-600 m-auto`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-1 pt-1 pb-4 text-lg text-gray-700 rounded-xl lightbggradient dark:bggradient">
                              <div className="grid grid-cols-3 ml-3 md:ml-3">
                                {collection.udas.map((uda) => (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      navigate("/uda", {
                                        state: {
                                          walletData,
                                          vault,
                                          uda,
                                          locationHistory: [
                                            window.location.hash,
                                          ],
                                        },
                                      })
                                    }
                                  >
                                    <img
                                      key={uda.id}
                                      src={uda.image}
                                      className="object-cover w-24 h-24 m-auto my-1.5 lg:my-6 rounded-lg lg:w-44 lg:h-44 "
                                      alt="unique digital asset"
                                    />
                                  </button>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto overflow-y-auto flex-grow-default scrollbar dark:darkscrollbar bg-transparent">
                  <div className="flex flex-col justify-center w-full h-full">
                    <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                      No UDAs in wallet
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              className={classNames(
                !tokensWalletFunded || loadingVault
                  ? "flex justify-center"
                  : "grid grid-cols-12 gap-6",
                "h-auto m-auto text-center border-gray-300 border-t-1/2 dark:border-newdarkmode-600 w-full py-2 px-6 lg:px-12 flex justify-center"
              )}
            >
              {loadingVault ? (
                <Loader className="w-8 h-8 m-auto" />
              ) : (
                <>
                  {tokensWalletFunded ? (
                    <>
                      <RoundedButton
                        className="w-full m-auto text-sm text-black border-gray-700 border-1 sm:px-2 col-span-6 dark:border-yellow-500 dark:text-yellow-500"
                        onClick={() => {
                          ReactGA.event({
                            category: "Wallet",
                            action: "Accept UDA",
                          });
                          navigate("/accepttransaction", {
                            state: {
                              walletData,
                              vault,
                              asset: null,
                              type: "UDA",
                              locationHistory: [window.location.hash],
                            },
                          });
                        }}
                      >
                        <div className="flex flex-row m-auto">
                          <span className="m-auto mr-1.5 text-lg font-bold">
                            +
                          </span>
                          <div className="m-auto text-sm lg:text-base">
                            Accept UDA
                          </div>
                        </div>
                      </RoundedButton>
                      <RoundedButton
                        className={classNames(
                          "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                        )}
                        onClick={() => {
                          ReactGA.event({
                            category: "Wallet",
                            action: "Generate invoice for UDA",
                          });
                          // We will need to determine how to decern which asset type we are dealing with
                          // and pass it to receive. Set as Asset for now.
                          navigate("/receive", {
                            state: {
                              walletData,
                              vault,
                              asset: null,
                              type: "UDA",
                              locationHistory: [window.location.hash],
                            },
                          });
                        }}
                      >
                        <div className="flex flex-row m-auto">
                          <span className="m-auto mr-1.5 text-lg font-bold">
                            +
                          </span>
                          <div className="m-auto text-xs lg:text-base">
                            Generate Invoice
                          </div>
                        </div>
                      </RoundedButton>
                    </>
                  ) : (
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-auto col-span-12"
                      )}
                      onClick={() => {
                        setOpenFundWalletModal(true);
                      }}
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto">Fund Vault</div>
                      </div>
                    </RoundedButton>
                  )}
                </>
              )}
            </div>
          </>
        )}
        <Modal open={openFundWalletModal} setOpen={setOpenFundWalletModal}>
          <FundWallet
            walletBalance={addBalance(walletData?.balance)}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            vault={vault}
            setOpenFundWalletModal={setOpenFundWalletModal}
          />
        </Modal>
        <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
          <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
        </Modal>
        <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      </div>
    );
  }
  return (
    <div className="flex justify-center w-full h-full m-auto text-gray-900 dark:text-gray-300">
      <Loader className="w-20 h-20 m-auto" />
    </div>
  );
};

export default Wallet;
