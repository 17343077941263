/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import ChevronLeft from "src/Components/Icons/ChevronLeft";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { NetworkContext } from "src/Hooks/NetworkContext";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import { classNames, addBalance } from "src/Hooks/util";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";

const ViewTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const { network } = useContext(NetworkContext);
  const { transaction, walletData, vault, conversion } = location.state;

  useEffect(() => {
    ReactGA.pageview("View Transaction");
    Mixpanel.track("Viewed Transaction Details", { Transaction: transaction });
  }, []);

  return (
    <div className="w-full h-full mx-auto overflow-y-auto text-sm text-gray-900 min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12 dark:text-gray-300">
      <div className="flex w-full flex-nowrap">
        <button
          type="button"
          className="flex flex-row my-3 ml-6 cursor-pointer sm:ml-9 xs:mb-6 gap-3"
          onClick={() =>
            navigate("/wallet", { state: { wallet: walletData.name, vault } })
          }
        >
          <ChevronLeft className="m-auto font-bold w-7 h-7" />
          <p className="my-auto text-base font-normal text-left text-black md:text-lg dark:text-green-500">
            {walletData.name}
          </p>
        </button>
      </div>
      <div className="w-full text-center">
        <button
          type="button"
          title="Copy wallet address"
          onClick={() => {
            navigator.clipboard.writeText(walletData?.address || "");
            setOpenCopyModal(true);
            Mixpanel.track("Copied Wallet Address", { Source: "Home Page" });
          }}
          className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 bg-gray-300 dark:bg-newdarkmode-600 font-thin cursor-pointer text-xs max-w-9/12 text-center w-auto m-auto flex flex-nowrap text-gray-800 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-400"
        >
          <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
            {walletData?.address}
          </p>
          <Copy className="flex flex-none w-6 h-6" />
        </button>
        <div className="w-10/12 m-auto ">
          <h1 className="mt-2 text-2xl font-bold text-center text-black dark:font-semibold dark:text-yellow-500 flex-grow-default">
            {addBalance(walletData?.balance).toLocaleString()}{" "}
            {network === "Bitcoin Testnet" ? "tSats" : "Sats"}
          </h1>
          <div className="grid grid-cols-2">
            <p className="my-2 text-sm font-light text-center text-black flex-grow-default dark:text-gray-400">
              {Number(
                addBalance(walletData?.balance) / 100_000_000
              ).toLocaleString(undefined, { minimumFractionDigits: 8 })}{" "}
              {network === "Bitcoin Testnet" ? "tBTC" : "BTC"}
            </p>
            <p className="my-2 text-sm font-light text-center text-black flex-grow-default dark:text-gray-400">
              {Number(
                (addBalance(walletData?.balance) / 100_000_000) * conversion
              ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
              USD
            </p>
          </div>
        </div>
        <div className="w-9/12 m-auto mt-4 grid grid-cols-2">
          <RoundedButton
            className="bg-yellow-500 dark:border-2 dark:border-yellow-500 text-base text-black py-1.5 mr-2"
            onClick={() => {
              ReactGA.event({
                category: "Wallet",
                action: "send",
              });
              Mixpanel.track("Tapped Send Bitcoin", {
                Source: "View Transaction",
              });
              navigate("/send", {
                state: {
                  ...location.state,
                  locationHistory: [window.location.hash],
                  type: "Bitcoin",
                  asset: {
                    allocations: [],
                    balance: addBalance(walletData?.balance),
                    dollarBalance: (
                      addBalance(walletData?.balance) * conversion
                    ).toFixed(3),
                    id: "",
                    name: "Satoshis",
                    ticker: "Sats",
                  },
                },
              });
            }}
          >
            Send
          </RoundedButton>
          <RoundedButton
            className="dark:border-2 dark:border-yellow-500 bg-yellow-500 text-base text-black py-1.5 ml-2"
            onClick={() => {
              Mixpanel.track("Tapped Receive Bitcoin", {
                Source: "View Transaction",
              });
              navigate("/receive", {
                state: {
                  ...location.state,
                  locationHistory: [window.location.hash],
                },
              });
            }}
          >
            Receive
          </RoundedButton>
        </div>
        <div className="flex flex-col w-auto">
          {!transaction.lightning && (
            <span
              className="flex w-auto px-6 m-auto mt-6 text-base font-semibold text-center text-gray-900 truncate max-w-11/12 sm:mt-9 sm:px-9 flex-nowrap dark:text-gray-300"
              title="View transaction on Mempool"
            >
              Transaction{" "}
              <a
                id="mempoolLink"
                href={`https://mempool.space/testnet/tx/${transaction.id}`}
                target="_blank"
                rel="noreferrer"
                className="underline truncate text-gray-800 dark:text-gray-300 font-normal w-auto m-auto ml-1.5"
                onClick={() => {
                  Mixpanel.track("Tapped Transaction ID", {
                    Transaction: transaction,
                    Source: "Transaction Details",
                  });
                }}
              >
                {transaction.id}
              </a>
            </span>
          )}
          <div className="flex px-6 py-3 mx-6 my-4 bg-gray-300 border-gray-300 rounded-lg shadow-md dark:bg-newdarkmode-600 sm:px-9 sm:py-6 sm:my-6 sm:mx-9 dark:shadow-lg border-1/2 dark:border-newdarkmode-600">
            <div className="flex-col w-full h-auto text-left">
              {Object.entries(transaction).map(([key, value]) => (
                <div key={key} className="w-full">
                  {!["action", "status", "id"].includes(key) ? (
                    <div
                      className={classNames(
                        key === "confirmationTime"
                          ? "flex flex-col"
                          : "flex flex-row",
                        "w-full my-1.5 sm:my-3"
                      )}
                    >
                      <p className="text-base font-medium text-black  dark:text-gray-100">
                        {key === "confirmationTime"
                          ? "confirmation time"
                          : JSON.stringify(key).replace(/['"]+/g, "")}
                        :{" "}
                      </p>
                      {key === "date" ? (
                        <p className="text-base font-normal text-black dark:text-gray-100 ml-1.5 truncate w-auto">
                          {new Date(value * 1000).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}
                        </p>
                      ) : key === "confirmationTime" && value !== null ? (
                        <div className="py-1.5">
                          {Object.entries(value).map(([k, val]) => (
                            <div key={k} className="flex flex-col">
                              <div className="ml-4 text-base font-normal text-black truncate dark:text-gray-100 max-w-80 xs:max-w-96 sm:max-w-full sm:w-auto">
                                <>
                                  <span className="dark:text-gray-100">
                                    {k}:
                                  </span>
                                  <span className="dark:text-gray-400">
                                    <>
                                      {" "}
                                      {k === "timestamp" &&
                                      typeof val === "number"
                                        ? new Date(
                                            val * 1000
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          })
                                        : typeof val === "number"
                                        ? val.toLocaleString()
                                        : val}
                                    </>
                                  </span>
                                </>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-base font-normal text-black dark:text-gray-400 ml-1.5 truncate max-w-80 xs:max-w-96 sm:max-w-full sm:w-auto">
                          {JSON.stringify(
                            value === null
                              ? "pending confirmation"
                              : typeof value === "number"
                              ? value.toLocaleString()
                              : value
                          )
                            .replace(/['"]+/g, "")
                            .replace(/[{}]+/g, "")}
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </div>
  );
};

export default ViewTransaction;
