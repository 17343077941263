/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import TextArea from "src/Components/Inputs/TextArea";
import ImportAssetTabs from "src/Components/Tabs/ImportAssetTabs";
import Search from "src/Components/Inputs/Search";
import PageWrapper from "src/Components/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { Asset, Location } from "src/types";
import { ImportAsset as ImportAssetResponse, ListAssets } from "src/types/core";
import Mixpanel from "src/lib/Mixpanel";
import getBlinding from "src/lib/getBlinding";
import { classNames } from "src/Hooks/util";
import Loader from "src/Components/Loaders/Loader";

const ImportAsset = () => {
  const [view, setView] = useState(0);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [genesis, setGenesis] = useState("");
  const [searchResults, setSearchResults] = useState([] as Asset[]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [disabled, setDisabled] = useState({
    rgb: false,
    genesis: false,
  });
  const [loading, setLoading] = useState(false);
  const [blinding, setBlinding] = useState({
    utxo: "",
    conceal: "",
    blinding: "",
  });

  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault } = location.state;

  ReactGA.pageview("ImportAsset");

  useEffect(() => {
    if (!location.state.blinding) {
      const setBlindingProperty = async () => {
        setBlinding(
          await getBlinding(
            walletData.name,
            vault.rgbAssetsDescriptorXpub,
            true
          )
        );
      };

      // call the function
      setBlindingProperty()
        // make sure to catch any error
        .catch(console.error);
    } else {
      setBlinding(location.state.blinding);
    }
  }, []);

  useEffect(() => {
    if (view === 1) {
      Mixpanel.track("Viewed Import Asset Search");
    }
    Mixpanel.track("Viewed Import Asset", {
      Page: view === 0 ? "Custom" : "Search",
    });
  }, [view]);

  useEffect(() => {
    if (location.state.genesis) setGenesis(location.state.genesis);
    (async () => {
      const key = "listAssets";
      const result = await lib.storageGet<Asset[]>([key]);

      if (result[key]) {
        const futureSearchResults = result[key];
        setSearchResults(futureSearchResults);
      }

      // try {
      //   const listAssets: ListAssets = await backend.list_assets(
      //     vault.rgbAssetsDescriptorXpub,
      //     "TODO: encryption secret"
      //   );

      //   setSearchResults(listAssets);
      //   await lib.storageSet({ [key]: listAssets });
      //   console.debug(`Value is set to ${listAssets}`);
      // } catch (resError) {
      //   console.error("error calling list_assets", resError);
      // }
    })();
  }, []);

  useEffect(() => {
    if (id !== "") {
      setDisabled({ rgb: false, genesis: true });
    } else if (genesis !== "") {
      setDisabled({ genesis: false, rgb: true });
    } else if (id === "" && genesis === "") {
      setDisabled({ genesis: false, rgb: false });
    }
  }, [id, genesis]);

  const handleSubmit = async (searchId: string | null) => {
    let assetData: ImportAssetResponse;
    try {
      if (searchId) {
        if (searchId.startsWith("rgb1")) {
          setLoading(true);
          assetData = await backend.import_asset(
            searchId,
            blinding.utxo,
            blinding.conceal
          );
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset`,
            message: "Server Error: Invalid RGB ID format",
          });
          setOpen(true);
          return;
        }
      } else if (id !== "") {
        if (id.startsWith("rgb1")) {
          setLoading(true);
          assetData = await backend.import_asset(
            id,
            blinding.utxo,
            blinding.conceal
          );
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset`,
            message: "Error: Invalid RGB ID format",
          });
          setOpen(true);
          return;
        }
      } else if (genesis !== "") {
        if (genesis.startsWith("rgbc1")) {
          setLoading(true);
          assetData = await backend.import_asset(
            genesis,
            blinding.utxo,
            blinding.conceal
          );
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset`,
            message: "Error: Invalid genesis contract format",
          });
          setOpen(true);
          return;
        }
      } else {
        setLoading(false);
        setError({
          title: `Error importing asset`,
          message: "Error: No asset provided",
        });
        setOpen(true);
        return;
      }

      if (assetData) {
        setLoading(true);
        navigate("/importdescription", {
          state: {
            walletData,
            vault,
            asset: assetData,
            source: { view: view === 0 ? "Custom" : "Search", param: search },
          },
        });
      } else {
        console.error("No assetData found");
      }
    } catch (assetError: any) {
      console.error("asset error in ImportAsset", assetError);
      if (assetError.toString().includes("Server error: Incorrect rgb id")) {
        setLoading(false);
        setError({
          title: `Error importing asset`,
          message: "Server error: Invalid RGB ID",
        });
        setOpen(true);
      } else if (assetError.toString().includes("rgbc1")) {
        setLoading(false);
        setError({
          title: "Error importing asset",
          message: "Server error: Invalid RGB genesis",
        });
        setOpen(true);
      } else {
        console.error("UNHANDLED ERROR in ImportAsset");
      }
    }
  };

  const addId = async (arg) => {
    setId(arg);
  };

  const tabs = [{ title: "Custom" }, { title: "Search" }];

  if (!blinding.utxo) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-screen m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradient dark:bg-newdarkmode-700"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <PageWrapper
      title="Import Asset"
      handlePageBack={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleCancel={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleSubmit={() => handleSubmit("")}
      loading={loading}
    >
      <ImportAssetTabs view={view} setView={setView} tabs={tabs} />
      {view === 0 && (
        <div className="text-gray-800 dark:text-gray-500">
          <TextArea
            className="w-full p-2 mt-2"
            placeholder="Enter asset ID here"
            rows={3}
            onChange={(e) => setId(e.target.value)}
            disabled={disabled.rgb}
          />
          <p className="my-4 text-sm font-normal text-center sm:text-base flex-grow-default">
            or
          </p>
          <TextArea
            className="w-full p-2"
            placeholder="Enter genesis contract"
            rows={3}
            value={genesis}
            onChange={(e) => setGenesis(e.target.value)}
            disabled={disabled.genesis}
          />
        </div>
      )}
      {view === 1 && (
        <div className="w-full h-64 my-auto mt-6 border-gray-300 rounded-lg border-1 dark:border-newdarkmode-600">
          <div className="sticky w-full px-6 m-auto">
            <Search
              className="w-full p-2 m-auto"
              search={search}
              setsearch={setSearch}
              disabled
            />
          </div>
          <div className="flex flex-col mr-2 overflow-y-auto scrollbar dark:darkscrollbar h-44">
            {searchResults.length ? (
              <>
                {searchResults
                  .filter((res) =>
                    res.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((asset) => (
                    <button
                      key={asset.id}
                      type="button"
                      className="ml-2"
                      onClick={() => {
                        handleSubmit(asset.id);
                      }}
                    >
                      <div className="px-6 py-3 text-base font-semibold text-left text-gray-900 cursor-pointer dark:text-gray-300 dark:hover:text-yellow-500 hover:text-gray-600">
                        {asset.name}
                      </div>
                    </button>
                  ))}
              </>
            ) : (
              <p className="m-auto text-base text-center text-gray-600 lg:text-lg dark:text-gray-300">
                No results at the moment
              </p>
            )}
          </div>
        </div>
      )}
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default ImportAsset;
