import React from "react";
import { motion } from "framer-motion";
import Loader from "src/Components/Loaders/Loader";

const RoundedButton = ({ className = "", ...props }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    type="button"
    className={`${className} rounded-lg hover:shadow-md shadow-sm flex justify-center font-medium px-3 py-1.5 my-auto text-center flex-nowrap`}
    onClick={() => props.onClick()}
    data-test-id={props["data-test-id"]}
  >
    {props.loading ? <Loader className="w-6 h-6 my-auto mr-3" /> : null}
    <span className="my-auto">{props.children}</span>
  </motion.button>
);

export default RoundedButton;
