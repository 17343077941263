/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";

import { NetworkContext } from "src/Hooks/NetworkContext";
import { classNames } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";

const Dropdown = ({ className, ...props }) => {
  const { network, setNetwork } = useContext(NetworkContext);

  return (
    <Menu as="div" className="relative m-auto text-left">
      <div>
        <Menu.Button disabled className={`${className}`}>
          {props.children}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className="absolute right-0 w-56 mt-2 bg-gray-100 shadow-lg origin-top-right rounded-md dark:bg-newdarkmode-600 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        >
          {props.items.map((item) => (
            <div key={item} className="">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active
                        ? "bg-gray-100 dark:bg-darkmode-700 text-gray-900 dark:text-gray-300"
                        : "text-gray-900 dark:text-gray-300",
                      "block px-4 py-4 text-base w-full h-auto text-left"
                    )}
                    onClick={() => {
                      Mixpanel.track("Tapped Switch Networks");
                      Mixpanel.track("Switched Network", {
                        "Old Network": network,
                        "New Network": item,
                      });
                      props.setSelected(item);
                    }}
                  >
                    <div className="m-auto">{item}</div>
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
