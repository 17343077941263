/* eslint-disable arrow-body-style */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import XMarkIcon from "../Icons/XMarkIcon";

const FullScreenModal = ({ ...props }) => {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto z-[999]"
        open={props.open}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center h-full text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-[85%] transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="z-40 inline-block w-full h-screen px-4 overflow-hidden text-left text-black mx-auto shadow-xl max-w-11/12 min-w-112 dark:text-gray-600 rounded-xl transform transition-all">
              <div className="absolute right-0 z-30 block pt-2 pr-6 lg:pr-12 top-6">
                <button
                  type="button"
                  className="m-3 text-black rounded-md dark:text-white dark:hover:text-gray-600 ring-0 order-1"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon
                    className="w-12 h-12 mix-blend-difference order-1"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <img
                className="max-h-screen max-w-screen mx-auto"
                src={props.uda}
                alt="unique digital asset"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FullScreenModal;
