import React from "react";

const Loader = ({ className, ...props }) => (
  <div className={`${className}`} {...props}>
    <div className="loader">
      <style>{`
        .loader {
          border: 3px solid #f3f3f3; /* Light grey */
          border-top: 3px solid #111112;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          animation: spin 2s linear infinite;
          margin: auto;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  </div>
);

export default Loader;
