/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-empty-function */
/* global chrome */
import React, { Fragment, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Modal from "src/Components/Modals/Modal";
import { ThemeContext } from "src/Hooks/ThemeContext";
import Dots from "src/Components/Icons/Dots";
import LightDarkToggle from "src/Components/Inputs/LightDarkToggle";
import { classNames, delay } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import { Location } from "src/types";
import lib, { isExtension } from "src/lib";
import { releaseNotes } from "src/constants";

const NavbarMenu = ({ className }) => {
  const navigate = useNavigate();
  let location = useLocation() as Location;
  const [checked, setChecked] = useState(false);
  const [newTab, setNewTab] = useState(false);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  const onChange = () => {
    Mixpanel.track("Toggled Theme", {
      "Old Setting": theme,
      "New Setting": theme === "dark" ? "light" : "dark",
    });
    if (setTheme) setTheme(theme === "dark" ? "light" : "dark");
    setChecked(!checked);
  };

  const handleRedirectToFullscreenTab = () => {
    const win = window.open(
      window.location.href
        .replace(window.location.hash, "")
        .replace(window.location.search, ""),
      "_blank"
    );
    setNewTab(!newTab);
    win?.focus();
  };

  const defaultLocation = {
    state: {
      wallet: "",
      walletData: {
        wallet: "",
        name: "",
        address: "",
        balance: {
          immature: 0,
          trusted_pending: 0,
          untrusted_pending: 0,
          confirmed: 0,
        },
        transactions: [],
        utxos: [],
      },
      vault: {
        btcDescriptorXprv: "",
        btcDescriptorXpub: "",
        btcChangeDescriptorXprv: "",
        btcChangeDescriptorXpub: "",
        rgbAssetsDescriptorXprv: "",
        rgbAssetsDescriptorXpub: "",
        rgbUdasDescriptorXprv: "",
        rgbUdasDescriptorXpub: "",
        xpubkh: "",
        xprvkh: "",
        mnemonic: "",
      },
      consignment: "",
      transaction: {
        amount: "",
        asset: "",
        assetType: "",
        fee: 0,
        message: "",
        note: "",
        id: "",
        date: 0,
        action: "",
        status: "",
        sender: {
          name: "",
          address: "",
        },
        recipient: {
          name: "",
          address: "",
          conceal: "",
        },
      },
      asset: {
        id: "",
        ticker: "",
        name: "",
        description: "",
        allocations: [],
        balance: BigInt(0),
        genesis: "",
      },
      uda: {
        id: "",
        name: "",
        image: "",
        description: "",
        collection: "",
        genesis: "",
        consignment: "",
        blinding: "",
        utxo: "",
        asset: {
          id: "",
          ticker: "",
          name: "",
          description: "",
          allocations: [],
          balance: BigInt(0),
          genesis: "",
        },
      },
      type: "",
      funded: false,
      address: "",
      conversion: 0,
      message: "",
      tabid: 0,
      pubkeyHash: "",
      id: "",
      uid: "",
      source: {
        view: "",
        param: "",
      },
      locationHistory: [],
      genesis: "",
      blinding: {
        conceal: "",
        blinding: "",
        utxo: "",
      },
      udasBlinding: {
        conceal: "",
        blinding: "",
        utxo: "",
      },
      lnCredentials: {
        login: "",
        password: "",
        accessToken: "",
        refreshToken: "",
      },
      lnInvoice: "",
      tokensWalletFunded: false,
      loadingVault: false,
      tokensWalletAddress: "",
      udasWalletAddress: "",
    },
    key: "",
  };

  const { VERSION } = process.env;

  const items = [
    {
      name: (
        <div className="flex flex-nowrap">
          <span className="my-auto text-left flex-grow-default">
            Toggle theme
          </span>
          <div className="float-right m-auto ml-auto mr-1">
            <LightDarkToggle />
          </div>
        </div>
      ),
      onClick: () => onChange(),
    },
    {
      name: "Fullscreen",
      onClick: () => handleRedirectToFullscreenTab(),
      hidden: window.innerHeight > 600,
    },
    {
      name: "Add account",
      disabled: true,
    },
    {
      name: "Account details",
      disabled: true,
    },
    {
      name: "Settings",
      disabled: true,
    },
    {
      name: "Contact support",
      onClick: () => {
        Mixpanel.track("Tapped Contact Support");
        const win = window.open(
          "https://t.me/joinchat/Ajbxchsrj75kMDRi",
          "_blank"
        );
        win?.focus();
      },
    },
    {
      name: `BitMask v${VERSION}`,
      onClick: () => setOpenVersionPopup(true),
    },
  ];

  if (isExtension) {
    items.splice(items.length - 1, 0, {
      name: "Log out",
      onClick: async () => {
        Mixpanel.reset();
        localStorage.clear();
        lib.storageClear();
        window.history.replaceState({}, "", window.location.href);
        location = defaultLocation;
        await chrome.runtime.sendMessage({ call: "reload_script_and_tabs" });
        await delay(1000);
        chrome.runtime.reload();
      },
    });
  } else {
    items.splice(items.length - 1, 0, {
      name: "Log out",
      onClick: async () => {
        Mixpanel.reset();
        localStorage.clear();
        lib.storageClear();
        window.history.replaceState({}, "", window.location.href);
        location = defaultLocation;
        navigate("/");
      },
    });
  }

  return (
    <Menu as="div" className="relative h-auto m-auto text-left">
      {({ open }) => (
        <>
          <Menu.Button className={`${className}`}>
            <Dots
              className="h-7 w-7 mt-2.5 rotate-90 text-gray-800 dark:text-gray-400 m-auto"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 w-56 mt-2 bg-gray-100 shadow-lg origin-top-right rounded-md dark:bg-newdarkmode-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              {items.map((item) => (
                <div key={item.name.toString()} className="">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        disabled={item.disabled}
                        className={classNames(
                          active
                            ? "bg-gray-100 hover:bg-gray-100 dark:bg-newdarkmode-600  dark:hover:bg-newdarkmode-600 text-black dark:text-gray-400"
                            : "text-gray-900 dark:text-gray-300",
                          item.hidden ? "hidden" : "",
                          "block px-4 py-4 text-sm w-full h-auto text-left disabled:pointer-events-none disabled:hover:bg-gray-100 disabled:text-gray-500 disabled:bg-gray-lightest dark:disabled:bg-newdarkmode-700 dark:disabled:text-newdarkmode-600 dark:disabled:hover:bg-newdarkmode-600"
                        )}
                        onClick={item.onClick ? () => item.onClick() : () => {}}
                      >
                        <div className="m-auto">{item.name}</div>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
          <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
            <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
          </Modal>
        </>
      )}
    </Menu>
  );
};

export default NavbarMenu;
