/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { getConversion } from "src/Hooks/util";

const TransactionFeeDropdown = ({ type, ...props }) => {
  const [selected, setSelected] = useState({
    name: props.items[0].name,
    amount: props.items[0].amount,
  });
  const [conversion, setConversion] = useState(0);

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <Menu as="div" className="relative text-left">
      {({ open }) => (
        <div className="my-1">
          <div>
            <Menu.Button className="inline-flex w-full px-2 py-2 text-sm font-medium text-gray-300 rounded-md border-1 border-newdarkmode-600 shadow-sm bggradient hover:bg-newdarkmode-600 focus:outline-none focus:ring-1 focus:ring-darkmode-700">
              <div className="flex flex-col">
                <div className="flex mb-1 flex-nowrap">
                  <p className="font-semibold text-gray-300 truncate">
                    {selected.name}
                  </p>
                </div>
                <div className="flex flex-nowrap">
                  <p className="font-normal text-gray-500 truncate">
                    {((selected.amount / 100_000_000) * conversion).toFixed(3)}{" "}
                    USD
                  </p>
                </div>
              </div>
              <ChevronDownIcon
                className="w-5 h-5 m-auto ml-auto mr-0"
                aria-hidden="true"
              />
            </Menu.Button>
            <h3 className="mt-1 text-xs font-normal text-gray-600 dark:text-gray-500">
              {props.label}
            </h3>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-10 w-full mt-2 shadow-lg origin-top-right rounded-md bg-newdarkmode-600 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              {props.items.map((item) => (
                <div className="py-1">
                  <Menu.Item
                    as="button"
                    onClick={() => {
                      setSelected({
                        name: item.name,
                        amount: item.amount,
                      });
                      if (type === "fee") {
                        props.setTransaction({
                          ...props.transaction,
                          fee: { name: item.name, amount: item.amount },
                        });
                      } else if (type === "limit") {
                        props.setTransaction({
                          ...props.transaction,
                          limit: { name: item.name, amount: item.amount },
                        });
                      }
                    }}
                  >
                    {({ active }) => (
                      <div className="flex flex-col mx-2">
                        <div className="flex flex-nowrap">
                          <p className="text-sm font-semibold text-gray-300 truncate">
                            {item.name}
                          </p>
                        </div>
                        <div className="flex flex-nowrap">
                          <p className="font-normal text-gray-500 truncate">
                            {item.amount} USD
                          </p>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default TransactionFeeDropdown;
