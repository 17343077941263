import React from "react";

const FullScreen = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    fill="currentColor"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.625 3.625L15.0125 7.2125L16.7875 8.9875L20.375 5.375L23.25 8.25V0.75H15.75L18.625 3.625ZM8.25 0.75H0.75V8.25L3.625 5.375L7.2125 8.9875L8.9875 7.2125L5.375 3.625L8.25 0.75ZM7.2125 15.0125L3.625 18.625L0.75 15.75V23.25H8.25L5.375 20.375L8.9875 16.7875L7.2125 15.0125ZM16.7875 15.0125L15.0125 16.7875L18.625 20.375L15.75 23.25H23.25V15.75L20.375 18.625L16.7875 15.0125Z"
    />
  </svg>
);

export default FullScreen;
