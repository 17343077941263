import lib from "src/lib";
import * as backend from "src/Backend";
import { BlindingUtxo } from "src/types";
import { GetWalletData } from "src/types/core";

async function getBlinding(
  walletName: string,
  rgbDescriptorXpub: string,
  isTokensBlinding: boolean
) {
  const walletDataTokens: GetWalletData = await backend.get_wallet_data(
    rgbDescriptorXpub
  );
  const key = isTokensBlinding
    ? `${walletName}.conceal`
    : `${walletName}.udasConceal`;
  const storageResult = await lib.storageGet<BlindingUtxo>([key]);

  if (
    storageResult[key] &&
    storageResult[key]?.utxo === walletDataTokens.utxos[0]
  ) {
    return storageResult[key];
  }
  const {
    conceal,
    blinding: blindingAPI,
    utxo,
  }: BlindingUtxo = await backend.get_blinded_utxo(walletDataTokens.utxos[0]);

  const blindedUnspent: BlindingUtxo = {
    conceal,
    blinding: blindingAPI,
    utxo,
  };
  await lib.storageSet({ [key]: blindedUnspent });
  return blindedUnspent;
}

export default getBlinding;
