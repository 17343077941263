/* eslint-disable react/destructuring-assignment */

import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ReactGA from "react-ga";

import MainNav from "src/Components/Navbar/MainNav";
import { MainNavContext } from "src/Hooks/MainNavContext";
import useWindowSize from "src/Hooks/useWindowSize";
import ErrorBoundary from "src/Hooks/ErrorBoundary";
import { isExtension } from "src/lib";

const Layout = () => {
  const size = useWindowSize();
  const [width, setWidth] = useState(0);
  const { mainNav } = useContext(MainNavContext);
  ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
    debug: false,
    gaAddress: "analytics.js",
    gaOptions: {
      cookieDomain: "none",
    },
  });
  ReactGA.ga("set", "checkProtocolTask");

  useEffect(() => {
    if (isExtension && window.location.search.includes("?popup")) {
      setWidth(400);
    } else {
      setWidth(window.innerWidth);
    }
  }, [mainNav, size]);

  return (
    <div
      className="flex flex-col h-screen overflow-x-hidden overflow-y-auto"
      style={{ width, maxWidth: width }}
    >
      <MainNav />
      <div className="w-full h-auto mx-auto overflow-x-hidden overflow-y-auto bg-gray-100 min-h-page dark:bggradient dark:bg-newdarkmode-700 dark:darkscrollbar">
        <div className="flex flex-col w-full h-auto min-h-full overflow-hidden">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Layout;
