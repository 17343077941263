/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import QRCode from "react-qr-code";
import { TEST_IDS } from "tests/ids";
import { Location } from "src/types";
import { NetworkContext } from "src/Hooks/NetworkContext";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Success2 from "src/Components/Icons/Success2";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import { bech32ToBase64 } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";

// TODO: replace QR lib with one that can handle a 4k+ char QR code

const Success = () => {
  const [open, setOpen] = useState(false);
  const { network } = useContext(NetworkContext);
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { wallet, vault, consignment, transaction, asset, lnCredentials } =
    location.state;
  ReactGA.pageview("Success");

  if (transaction.assetType === "Accepted") {
    Mixpanel.track("Accepted Asset Transaction", {
      Asset: asset,
      Transaction: transaction,
    });
  } else if (transaction.assetType === "token") {
    Mixpanel.track("Sent Asset", { Transaction: transaction, Asset: asset });
    Mixpanel.track("Viewed Send Asset Consignment");
  } else if (transaction.assetType === "sats") {
    Mixpanel.track("Viewed Transaction Completion Page", {
      Transaction: transaction,
    });
  }

  return (
    <div className="flex flex-col justify-center w-full h-full m-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="h-auto m-auto text-center">
        {transaction.assetType !== "Asset" &&
        transaction.assetType !== "Assets" &&
        transaction.assetType !== "token" &&
        transaction.assetType !== "UDA" ? (
          <>
            <h1 className="m-auto text-2xl font-normal text-center text-black max-w-10/12 xs:text-3xl lg:text-4xl flex-grow-default dark:text-green-500">
              Transaction submitted successfully
            </h1>
            <img
              className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
              src="/images/greenCheck.png"
              alt="green check mark"
            />
            {transaction.assetType !== "Lightning" && (
              <span
                className="hidden w-auto mx-6 mt-6 text-base font-semibold text-left text-gray-900 truncate flex-nowrap dark:text-gray-300"
                title="View transaction on Mempool"
              >
                <a
                  href={`https://mempool.space/${
                    network === "Bitcoin Testnet" ? "testnet" : "mainnet"
                  }/tx/${transaction.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="w-auto pb-6 m-auto text-lg font-medium text-gray-800 underline truncate dark:text-green-500"
                >
                  View Transaction on Mempool {transaction.id}
                </a>
              </span>
            )}
          </>
        ) : (
          <div className="text-gray-900 dark:text-gray-500">
            <div className="w-full h-auto m-auto border-gray-300 sm:mt-6 border-b-1 dark:border-newdarkmode-600">
              <div className="m-auto h-72 w-72">
                <QRCode
                  value="https://bitmask.app/consignment?buffer="
                  style={{
                    height: "auto",
                    maxWidth: 400,
                    width: "90%",
                    margin: "auto",
                  }}
                  size={256}
                  viewBox="0 0 256 256"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <h1 className="px-3 my-3 text-xl font-semibold text-gray-900 dark:text-gray-300">
                Consignment (send to the recipient to complete the transfer):
              </h1>
              <button
                type="button"
                className="py-1.5 px-3 rounded-lg m-auto text-gray-600 cursor-pointer hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 max-w-88 sm:max-w-152  bg-gray-300 dark:bg-newdarkmode-600 font-normal text-center w-auto flex flex-nowrap"
                onClick={() => {
                  Mixpanel.track("Copied Consignment ID", {
                    Source: `Send ${transaction.assetType} Consignment`,
                  });
                  navigator.clipboard.writeText(bech32ToBase64(consignment));
                  setOpen(true);
                }}
                title="Copy Consignment"
              >
                <div className="flex w-full m-auto flex-nowrap">
                  <p
                    className="w-[85%] mt-2 text-base font-light truncate sm:w-full"
                    data-test-id={TEST_IDS.success.consignment}
                  >
                    {bech32ToBase64(consignment)}
                  </p>
                  <Copy className="my-auto ml-1 w-9 h-9" />
                </div>
              </button>
            </div>
          </div>
        )}
        <RoundedButton
          className="w-8/12 py-3 mx-auto mt-4 text-base text-black bg-yellow-500 sm:w-30"
          onClick={() =>
            navigate("/wallet", {
              state: { wallet, vault, lnCredentials },
            })
          }
        >
          Close
        </RoundedButton>
      </div>
      <CopiedToClipboard open={open} setOpen={setOpen} />
    </div>
  );
};

export default Success;
