/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ErrorModal from "src/Components/Modals/Error";
import lib from "src/lib";
import { GetEncryptedWallet } from "src/types/core";

const SignIn = () => {
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  ReactGA.pageview("Signin");
  const handleSubmit = async () => {
    // handle submit to rust backend
    try {
      const wallet = "wallet_01";
      const encryptedDescriptors = window.localStorage.getItem("descriptor");

      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }

      const vault: GetEncryptedWallet = await backend.get_encrypted_wallet(
        password,
        encryptedDescriptors
      );
      console.debug("aja");
      await lib.storageSet({ vault });
      console.debug(`Value is set to ${vault}`);
      console.debug("got vault");

      ReactGA.event({
        category: "Sign in",
        action: "Sign in",
      });
      navigate("/wallet", { replace: true, state: { wallet, vault } });
    } catch (err) {
      setError({
        title: `Error signing in`,
        message: "Account not found on device",
      });
      setOpen(true);
    }
  };

  return (
    <div className="h-full m-auto text-gray-900 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12 dark:text-gray-300">
      <div className="flex flex-col justify-center h-full m-auto text-center">
        <div className="h-auto my-auto">
          <h1 className="mt-2 text-3xl font-bold text-center flex-grow-default">
            GM! ⚡
          </h1>
          <p className="my-2 text-sm font-normal text-center text-gray-900 flex-grow-default dark:text-gray-500">
            Welcome to your utility gateway wallet for Bitcoin.
          </p>
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Enter Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            focus
          />
          <RoundedButton
            className="w-10/12 py-3 mx-auto mt-4 text-lg text-black bg-yellow-500 sm:text-base"
            onClick={() => handleSubmit()}
          >
            Sign In
          </RoundedButton>
          <p className="my-4 text-sm font-light text-center text-gray-800 dark:text-gray-500 flex-grow-default">
            or
          </p>
          <p className="mt-2 font-normal text-center text-gray-900 cursor-pointer flex-grow-default mb-14 dark:text-gray-500">
            Load using{" "}
            <button
              type="button"
              onClick={() => navigate("/walletimport")}
              className="underline"
            >
              Secret Recovery Phrase
            </button>
          </p>
          <p className="mt-32 font-normal text-center text-gray-900 cursor-pointer dark:text-gray-500">
            Need help?{" "}
            <button
              type="button"
              className="underline"
              onClick={() => {
                const win = window.open(
                  "https://t.me/joinchat/Ajbxchsrj75kMDRi",
                  "_blank"
                );
                win?.focus();
              }}
            >
              Contact bitmask support.
            </button>
          </p>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default SignIn;
