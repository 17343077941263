/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";

const ConfirmConnect = () => {
  const [loading, _setLoading] = useState(false);
  const location = useLocation() as Location;
  ReactGA.pageview("Confirm Connect");
  const { message, tabid, pubkeyHash, id, uid } = location.state;

  useEffect(() => {
    window.onbeforeunload = () => {
      chrome.runtime.sendMessage({
        call: "focus_last_active_tab",
      });
      chrome.runtime.sendMessage({
        call: "reload_script_and_tabs",
      });
    };
  }, []);

  return (
    <div className="flex justify-center w-11/12 font-jetbrains h-screen mx-auto overflow-y-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col justify-center w-full h-full mx-auto content-evenly">
        <h1 className="mb-20 text-3xl font-normal text-center text-black dark:text-white">
          Request to connect
        </h1>
        <div className="flex flex-col justify-center w-full m-auto">
          <div className="flex flex-row w-full m-auto pb-20">
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src="/images/logo_128.png"
                className="w-12 h-12 m-auto rounded-full order-2"
                alt="profile, or collection"
              />
              <div className="pt-1 pl-2 pb-6 text-base font-light text-center dark:text-gray-300">
                BitMask Wallet
              </div>
            </div>
            <div className="border-b-1 border-dashed w-120 border-gray-500 mb-6" />
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src={
                  ![undefined, "undefined"].includes(uid)
                    ? `${process.env.REACT_APP_BUCKET_URI}/${uid}-profile`
                    : "/images/defaultAvatar.png"
                }
                className="w-12 h-12 m-auto rounded-full order-2"
                alt="profile, or collection"
              />
              <div className="pt-1 pl-2 pb-6 text-base font-light text-center dark:text-gray-300">
                DIBA account
              </div>
            </div>
          </div>
        </div>
        <div className="pt-9 pl-2 mt-6 text-lg font-light text-center dark:text-gray-300 border-t-1 border-gray-800 pb-20">
          You are about to connect your BitMask wallet to your DIBA account.
        </div>
        <div className="w-9/12 m-auto mt-12 grid grid-cols-2">
          <RoundedButton
            className="mr-2 text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
            onClick={() => {
              chrome.runtime.sendMessage({
                call: "reload_script_and_tabs",
              });
              chrome.tabs.sendMessage(
                tabid,
                { authenticated: "no", answer: message, pubkeyHash, id },
                (response) => {
                  chrome.runtime
                    .sendMessage({
                      call: "focus_last_active_tab",
                    })
                    .then(() => window.close());
                }
              );
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className="ml-2 text-base bg-yellow-500 text-darkmode-900"
            onClick={() => {
              chrome.tabs.sendMessage(
                tabid,
                { authenticated: "yes", answer: message, pubkeyHash, id },
                (response) => {
                  console.log(response);
                  chrome.runtime
                    .sendMessage({
                      call: "focus_last_active_tab",
                    })
                    .then(() => window.close());
                }
              );
            }}
            loading={loading}
          >
            Connect
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmConnect;
