/* eslint-disable arrow-body-style */
import React from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";

const Card1 = ({ handleChange, ...props }) => {
  return (
    <div className="flex flex-col justify-center w-full h-auto py-12 m-auto mx-3 text-left leading-[2.15rem] xs:mx-6 xs:bg-gray-100 xs:shadow-xl dark:text-white sm:w-136 rounded-xl xs:dark:bg-newdarkmode-600">
      <Circles steps={props.steps} />
      <h1 className="mt-8 text-3xl font-semibold text-center flex-grow-default ">
        Secure Your Wallet
      </h1>
      <p className="px-6 my-6 text-xl font-normal xs:px-12 flex-grow-default">
        Your secret seed phrase is the key to access your BitMask Wallet.
      </p>
      <p className="px-6 my-3 text-xl font-normal xs:px-12 flex-grow-default">
        Be sure to keep your seed phrase in a secure place.{" "}
        <span className="text-xl font-semibold">
          Loss of your seed phrase means loss of access to your wallet and your
          funds!
        </span>{" "}
        Write down your seed phrase and store it in your most secure and safe
        location.{" "}
      </p>
      <RoundedButton
        className="w-9/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
        onClick={() => {
          props.setSteps([
            { name: "Set Password", href: "#", status: "complete" },
            { name: "Secure Wallet", href: "#", status: "complete" },
            {
              name: "Confirm Seed Phrase",
              href: "#",
              status: "current",
            },
          ]);
          handleChange("confirmPhrase");
        }}
      >
        Start
      </RoundedButton>
    </div>
  );
};

export default Card1;
