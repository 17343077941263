/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

import { classNames } from "src/Hooks/util";

const Circles = ({ ...props }) => {
  return (
    <nav aria-label="Progress" className="relative px-10 m-auto text-center">
      <ol className="relative flex justify-center">
        {props.steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== props.steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-green-500" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-green-500 rounded-full">
                  <CheckIcon
                    className="w-5 h-5 text-black dark:text-darkmode-900"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-300 dark:bg-newdarkmode-600 dark:opacity-75" />
                </div>
                <div
                  className="relative flex items-center justify-center w-8 h-8 bg-green-500 border-2 border-green-500 rounded-full dark:bggradient sm:dark:bg-newdarkmode-600"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 bg-green-500 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-300 dark:bg-newdarkmode-600 dark:opacity-75" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-gray-100 border-2 border-gray-300 rounded-full group dark:bggradient sm:dark:bg-newdarkmode-600 dark:border-newdarkmode-600 hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Circles;
