import React from "react";

const Discord = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 2500 1828"
  >
    <path
      d="M2079.9,154.9c270.7,398,404.4,847,354.4,1363.9c-0.2,2.2-1.3,4.2-3.1,5.5c-205,150.5-403.6,241.9-599.5,302.5
        c-3.1,0.9-6.5-0.2-8.4-2.8c-45.2-63-86.4-129.3-122.4-199c-2.1-4.1-0.2-9,4.1-10.7c65.3-24.6,127.4-54.1,187.1-89
        c4.7-2.8,5-9.5,0.7-12.7c-12.7-9.4-25.2-19.3-37.2-29.2c-2.2-1.8-5.3-2.2-7.8-1c-387.7,179-812.4,179-1204.7,0
        c-2.5-1.1-5.6-0.7-7.8,1c-12,9.9-24.6,19.7-37.1,29.1c-4.3,3.2-4,10,0.7,12.7c59.7,34.2,121.8,64.4,187,89.1
        c4.2,1.6,6.2,6.4,4.1,10.5c-35.2,69.8-76.3,136.2-122.4,199.1c-2,2.5-5.3,3.7-8.4,2.8c-194.9-60.6-393.5-151.9-598.6-302.5
        c-1.7-1.3-2.9-3.4-3.1-5.6c-41.8-447.1,43.4-899.8,354.1-1363.9c0.7-1.2,1.9-2.2,3.2-2.8C567.7,81.9,731.5,30.2,902.7,0.8
        c3.1-0.5,6.2,1,7.9,3.7c21.2,37.5,45.3,85.5,61.7,124.7c180.5-27.6,363.8-27.6,548,0c16.4-38.4,39.7-87.3,60.8-124.7
        c1.5-2.8,4.7-4.3,7.9-3.7C1760.2,30.3,1924,82,2076.8,152C2078.1,152.6,2079.2,153.6,2079.9,154.9L2079.9,154.9z M1064,1005
        c1.9-132.2-94.5-241.5-215.4-241.5c-120,0-215.4,108.4-215.4,241.5c0,133.1,97.3,241.5,215.4,241.5
        C968.6,1246.5,1064,1138.1,1064,1005z M1860.5,1005c1.9-132.2-94.5-241.5-215.4-241.5c-120,0-215.4,108.4-215.4,241.5
        c0,133.1,97.3,241.5,215.4,241.5C1766,1246.5,1860.5,1138.1,1860.5,1005z"
    />
  </svg>
);

export default Discord;
