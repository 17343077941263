/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from "react";

interface Network {
  network: string;
  setNetwork?: React.Dispatch<React.SetStateAction<string>>;
}

export const NetworkContext = createContext<Network>({ network: "testnet" });

const NetworkContextProvider = ({ ...props }) => {
  const [network, setNetwork] = useState("Bitcoin Testnet");

  return (
    <NetworkContext.Provider value={{ network, setNetwork }}>
      {props.children}
    </NetworkContext.Provider>
  );
};

export default NetworkContextProvider;
