import React from "react";
import QRCode from "react-qr-code";
import { generateMnemonic } from "bip39";
import ReactGA from "react-ga";

const PaperWallet = () => {
  const mnemonic = generateMnemonic();
  const link = `${window.location.origin}/#/walletimport/${btoa(mnemonic)}`;
  ReactGA.pageview("Paper Wallet");

  return (
    <div className="flex flex-col justify-center h-auto m-auto overflow-y-auto bg-gray-100 min-h-page dark:bggradient dark:bg-newdarkmode-700">
      <div className="flex justify-center my-auto">
        <div className="w-full h-auto py-8 m-auto text-center bg-gray-100 sm:w-136 sm:shadow-xl rounded-xl dark:bggradient sm:dark:bg-newdarkmode-600">
          <div className="h-full text-center">
            <h1 className="mt-2 text-3xl font-bold text-center text-black sm:text-4xl dark:text-white flex-grow-default">
              Paper Wallet
            </h1>
            <h2 className="mt-2 text-2xl font-bold text-center text-black sm:text-4xl dark:text-white flex-grow-default">
              <a href={link}>{window.location.host}</a>
            </h2>
            <div style={{ paddingTop: "30px" }}>
              <QRCode value={link} className="m-auto mb-6" size={288} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaperWallet;
