import React from "react";

const Twitter = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 232.9 232.9"
  >
    <path
      d="M190.6,70.5c-5.5,2.4-11.3,4.1-17.5,4.8c6.3-3.8,11.1-9.7,13.4-16.8c-5.9,3.5-12.4,6-19.3,7.4c-5.5-5.9-13.4-9.6-22.2-9.6
        c-19.6,0-34.1,18.3-29.6,37.3c-25.3-1.3-47.7-13.4-62.7-31.8c-8,13.7-4.1,31.5,9.4,40.6c-5-0.2-9.7-1.5-13.8-3.8
        c-0.3,14.1,9.8,27.3,24.4,30.2c-4.3,1.2-9,1.4-13.7,0.5c3.9,12.1,15.1,20.9,28.4,21.1c-12.8,10-28.9,14.5-45,12.6
        c13.5,8.6,29.4,13.7,46.6,13.7c56.4,0,88.3-47.7,86.4-90.4C181.3,82,186.5,76.6,190.6,70.5z"
    />
  </svg>
);

export default Twitter;
