import React from "react";

const Telegram = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 234.5 196.7"
  >
    <path
      id="Icon_awesome-telegram-plane"
      d="M169.8,62.1l-16,75.2c-1.2,5.3-4.3,6.6-8.8,4.1l-24.3-17.9L109,134.8
        c-1.2,1.5-3,2.4-4.9,2.4l1.7-24.8l45-40.7c2-1.7-0.4-2.7-3-1l-55.7,35.1l-24-7.5c-5.2-1.6-5.3-5.2,1.1-7.7l93.7-36.1
        C167.4,52.9,171.2,55.5,169.8,62.1L169.8,62.1z"
    />
  </svg>
);

export default Telegram;
