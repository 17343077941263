import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import Plus from "../Icons/Plus";
import Minus from "../Icons/Minus";

const DropDown = ({ ...props }) => (
  <div className="w-full pt-4 mb-12 sm:px-30">
    <div className="w-full mx-auto px-6 bggradient sm:rounded-2xl">
      {props.faqs.map((faq) => (
        <Disclosure key={faq.question}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open
                    ? "border-0 text-yellow-500"
                    : "border-b-1 border-yellow-500"
                } flex flex-nowrap flex-shrink-0 w-full px-4 py-10 text-lg font-semibold text-left text-gray-200 ease-in-out hover:text-yellow-300`}
              >
                {open ? (
                  <Minus className="min-w-8 min-h-8 max-w-8 max-h-8 mr-4 my-auto" />
                ) : (
                  <Plus className="min-w-8 min-h-8 max-w-8 max-h-8 mr-4 my-auto" />
                )}
                <span className="text-base font-light my-auto lg:text-lg font-integral">
                  {faq.question}
                </span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="max-w-6xl py-4 mx-4 text-base font-light text-gray-300 leading-9">
                  {faq.answer}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  </div>
);

export default DropDown;
